import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@mui/material";
import SkinImage from "../../../../assets/Images/AfterCare.jpg";
import HIcon from '../../../../assets/Images/logo.png';
import "../../../../MainCSS.css";
import "./After_cat.css"
import { green } from "@mui/material/colors";
const After_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_Cont">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_Grid"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1">
            <div className="D6_ADS_G1_Div">
              {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                AbAfter RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDiv'>
                <div className='D6_SL_WS_HIcon'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1_After'>AFTER CARE SUPPORT</Typography>
            </div>
              <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
Addiction is a complex, demanding, and frustrating condition, but effective treatment can help to improve the lives of people who struggle with it. When treatment options are extensively available and tailored to the needs of the individual, better results are possible.
</Typography>
<Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
Aftercare services help you maintain the coping skills you learned in rehab so you can continue to build a healthy, fulfilling life after graduating from a recovery program.
</Typography>



<ul className="ul_online">
            
            <li
                component="p"
                className="D6_ADS_G1_DivP_Online_B"
              >
Outpatient Day Care Program                </li>
                <li  component="p"
                className="D6_ADS_G1_DivP_Online_B">
Sober Residences Care Program</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B">
 Alcoholic Anonymous Meeting Program 
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B">
Alumni Meet Program  
</li>
                <li
                component="p"
                className="D6_ADS_G1_DivP_Online_B"
              >
                Meetings of AA/NA 
                </li>


                <li  component="p"
                className="D6_ADS_G1_DivP_Online_B">
One –O One discussions  
</li>

     
            </ul>
            <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
<span style ={{color: 'green'}} >Aftercare and sober living programs promote self-efficacy, coping skills and relapse prevention in long-term recovery.</span>
</Typography>
            <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
Get in touch with us on <span style ={{color: 'green'}} >+91999936140</span>
</Typography>    
 
 </div>
          </Grid>

          <Grid item xs={12} sm={6} className="D6_ADS_Grid1">
            <div className="D6_ADS_G1_Div" >
              <img src={SkinImage} alt="Image" className="D6_ADS_Img" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default After_cat;
