import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@mui/material";
import SkinImage from "../../../assets/Images/alcohol_add.jpg";
import HIcon from '../../../assets/Images/logo.png';
import "../../../MainCSS.css";
import "./Alcohol_cat.css"
const Alcohol_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_ContAl">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_GridAl"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1Al">
            <div className="D6_ADS_G1_DivAl">
              {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                AbFam RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDivAl'>
                <div className='D6_SL_WS_HIconAl'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1Al'>ALCOHOL ADDICTION TREATMENT</Typography>
            </div>
            <Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
According to the American Psychiatric Association (APA), alcohol addiction, [alcoholism] or alcohol use disorder (AUD), is considered a mental health disorder in which the drug causes lasting changes in the brain’s functioning. 
These changes make continued use and relapse more likely in the future. Because symptoms can range from mild to severe in intensity, alcoholism can create numerous effects on someone's mental, physical, social and spiritual health.
</Typography>

<Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
Our alcohol addiction starts with Medical Detox under the supervision of a team of licensed medical professionals. Medical detox refers to ridding the body of toxic addictive substances. 
Detox is an essential first step in recovery from alcohol addiction, since alcohol withdrawal is life-threatening when severe, medical alcohol detox is an absolute necessity. A medical detox can prevent discomfort and complications during alcohol withdrawal.
</Typography>

<Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
Your addiction recovery treatment does not stop at alcohol detox. From this program, you can transition into our Residential Care which includes the 12-step program of Alcoholics Anonymous.
</Typography>

<Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
Residential Care is a good choice for anyone who wants to focus completely on recovery without the stress or distractions of work, school, or social obligations. It allows for a thorough immersion in the recovery process and may be a good choice for people who have tried other treatments unsuccessfully. Inpatient treatment for alcohol rehabilitation may last anywhere from 45 days to 3 months or longer — recovery times depend on the needs of the individual.
 </Typography>

 <Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
<span style ={{color: 'green'}} >Alcoholism is a disease &amp; we have to treat it as a disease.</span>
 </Typography>

 <Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
<span style ={{color: 'green'}} >Contact us to take your first step towards Recovery . . .</span>
 </Typography>

  

              
  
 </div>
          </Grid>

          <Grid item xs={12} sm={6} className="D6_ADS_Grid1Al">
            <div className="D6_ADS_G1_DivAl" >
              <img src={SkinImage} alt="Image" className="D6_ADS_ImgAl" />
            </div>
          </Grid>

         
      
        </Grid>

      </Container>
    </div>
  );
};

export default Alcohol_cat;
