import React from 'react';
import './Contact_BgImage_CSS.css';
import { Dialog,Grid, DialogContent, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AForm from '../../common/AppointmentForm/D6_DASAC_Form'
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function Contact_BgImage() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    return(
        <Grid container justify="center" alignItems="center" className="Dec6_PYG_Grid">
        <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PYG_Grid1"  >
        <div className="Dec6_Industries_BgImage_Block_C">
         
            <Dialog
        open={open}

        // TransitionComponent={Transition}
        onClose={handleClose}
        className='Dialog_CButton'
        PaperProps={{ elevation: 0 }}
        disableScrollLock={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className='icon-close-btn'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
            Enquiries
          </Typography>
          <AForm/>
        </DialogContent>
      </Dialog>
        </div>
        </Grid>
        </Grid>
    );
}

export default Contact_BgImage;