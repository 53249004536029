import React from 'react'
import Family_below_img from './Family_below_img'
import Fam_cat from './Family_cat'
import Family_support_BgImage from './Family_support_BgImage'
import './Family_support.css'
const Family_support = () => {
    return (
        <>
                         <div className='Family_BG'>

          <Family_support_BgImage  />  
         <Family_below_img />
         </div>
         <Fam_cat />
        </>
    )
}

export default Family_support
