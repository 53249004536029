import React from 'react';
import './Alcohol_below_img.css';
import "../../../MainCSS.css";


function Alcohol_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_IAl">
            <h3 className="Dec6_Services_WCU_Cont_H_IAl">
            ALCOHOL ADDICTION TREATMENT           </h3>
            <p className="Dec6_Services_WCU_Cont_P_IAl">
            Empowering Recovery through the steps 
                         </p>    
            <i  className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_IAl"></i>
        </div>
    );
}

export default Alcohol_below_img;