import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Hair from '../../../assets/Images/AA_Home1.jpg'
import Laser from '../../../assets/Images/AA_Home3.jpg'
import Skin from '../../../assets/Images/AA_Home2.jpg'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import './D6_SL_SC_CSS.css'
import { Dialog, DialogContent, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AForm from '../../common/AppointmentForm/D6_DASAC_Form'
import Slide from '@mui/material/Slide';
import { makeStyles } from '@mui/styles';



const NextArrow = ({ onClick }) => {
  return (
    <div className="nextArrow" onClick={onClick}>
      <BsChevronRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="prevArrow" onClick={onClick}>
      <BsChevronLeft />
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function D6_SL_SCarousel() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />
  };
  const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setOpen(false);
  };
  return (
      <div className="D6_SL_SC_Slider_Div">
    <Slider {...settings}>
      <div className="D6_SL_Img1_div">
          <img src={Skin} alt="Image1" className='img1'/>    
          <div className="D6_SC_SliderDiv" >
              <h3 className="D6_SC_SliderDivH1" >
RESIDENTIAL CARE
              </h3>
              {/* <h3 className="D6_SC_SliderDivH2" >
              Consectetuer adipiscing elit
              </h3>
              <h3 className="D6_SC_SliderDivH2" >
              Consectetuer adipiscing elit
              </h3> */}
                <button className="D6_SL_SL_Button"
                     onClick={handleClickOpen}>
Request a service               
 </button>
                {/* <Dialog open={open} 
                TransitionComponent={Transition}
                onClose={handleClose} className="Dialog_CButton"
                aria-labelledby="form-dialog-title">
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <AForm/>
                </Dialog> */}
          </div>
        </div>
        <div >
          <img src={Laser} alt="Image1" className='img1'/>
          <div className="D6_SC_SliderDiv" >
              <h3 className="D6_SC_SliderDivH1">
 OUTPATIENT CARE </h3>
              {/* <h3 className="D6_SC_SliderDivH2" >
              Tincidunt ut laoreet dolore
              </h3>
              <h3 className="D6_SC_SliderDivH2" >
              Tincidunt ut laoreet dolore
              </h3> */}
              <button className="D6_SL_SL_Button"
                     onClick={handleClickOpen}>
Request a service               
                </button>
                <Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className='icon-close-btn'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Request a service               
          </Typography>
          <AForm/>
        </DialogContent>
      </Dialog>
                {/* <Dialog open={open} 
                TransitionComponent={Transition}
                onClose={handleClose} className="Dialog_CButton"
                aria-labelledby="form-dialog-title">
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <AForm/>
                </Dialog> */}
          </div>
        </div>
        <div >
          <img src={Hair} alt="Image1" className="img1"/>
          <div className="D6_SC_SliderDiv">
              <h3 className="D6_SC_SliderDivH1" >
ONLINE PATIENT CARE             </h3>
              {/* <h3 className="D6_SC_SliderDivH2" >
              Tincidunt ut laoreet dolore
              </h3>
              <h3 className="D6_SC_SliderDivH2" >
              Tincidunt ut laoreet dolore
              </h3> */}
              <button className="D6_SL_SL_Button"
                     onClick={handleClickOpen}>
Request a service               
                </button>
                {/* <Dialog open={open} 
                TransitionComponent={Transition}
                onClose={handleClose} className="Dialog_CButton"
                aria-labelledby="form-dialog-title">
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <AForm/>
                </Dialog> */}
          </div>
        </div>
    </Slider>
    </div>
  );
}