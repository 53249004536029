import React from 'react'
import Appointment from './Contact_SAppointmentS/D6_DASAC_SA'
import Map_Info from './Contact_MapInfoSection/D6_DASAC_CMI'
import Contact_BgImage from './Contact_BGImagePart/Contact_BgImage'

const D6_DASAC_Contact = () => {
    return (
        <>
            <Contact_BgImage/>
            <Map_Info/>
        </>
    )
}

export default D6_DASAC_Contact
