import React from 'react';
import './Family_below_img.css';
import "../../../../MainCSS.css";


function Family_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_I_Fam">
            <h3 className="Dec6_Services_WCU_Cont_H_I_Fam">
            FAMILY SUPPORT PROGRAM            </h3>
            <p className="Dec6_Services_WCU_Cont_P_I_Fam">
            Treating the entire Family
                        </p>
                                    <i className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_I_Fam"></i>
        </div>
    );
}

export default Family_below_img;