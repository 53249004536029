import React from 'react'
import '../../../../MainCSS.css'
import Inpatient_care_below_img from './Inpatient_care_below_img'
import Inpatient_care_BgImage from './Inpatient_care_BgImage'
import Inpatient_cat from './Inpatient_cat'
import './Inpatient_care.css'
const Inpatient_care = () => {
    return (
        <>         
         <div className='Inpatient_BG'>

          <Inpatient_care_BgImage  />  
          <div className='Inpatient_below_img1'>

          <Inpatient_care_below_img />
          <Inpatient_cat />
          </div>
          </div>

        </>
    )
}

export default Inpatient_care
