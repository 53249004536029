import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@mui/material";
import SkinImage from "../../../../assets/Images/Traning2.jpg";
 import HIcon from '../../../../assets/Images/logo.png';
import "../../../../MainCSS.css";
import "./Training_cat.css"
const Training_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_ContTr">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_GridTr"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1Tr">
            <div className="D6_ADS_G1_DivTr">
              {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                AbFam RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDivTr'>
                <div className='D6_SL_WS_HIconTr'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1Tr'>INTERNSHIP PROGRAM </Typography>
            </div>
            <Grid item sm={12} xs={12} className="D6_ADS_Grid1Tr">
   
   <ul className="ulTr">
   <li
       component="p"
       className="D6_ADS_G1_DivPTr_B"
     >
     First Step Wellness now offers a unique opportunity for students and mental health professionals.  
       </li>
<li  component="p"
       className="D6_ADS_G1_DivPTr_B">
Get acquainted with the basics of addiction and addiction treatment-Experience the wide variety of treatment modalities offered within our holistic model </li>
<li  component="p"
       className="D6_ADS_G1_DivPTr_B">
Get exposed to residential and therapeutic community settings  </li>
<li  component="p"
       className="D6_ADS_G1_DivPTr_B">
Expand your knowledge base and gain valuable experience in the upcoming field of addiction treatment  </li>
<li  component="p"
       className="D6_ADS_G1_DivPTr_B">
Institutions such as schools, colleges and clubs </li>
<li  component="p"
       className="D6_ADS_G1_DivPTr_B">
Internships are across three categories: 30 hours, 60 hours and 100 hours </li>
  </ul>
 </Grid>
  

              
  
 </div>
          </Grid>

          <Grid item xs={12} sm={6} className="D6_ADS_Grid1Tr">
            <div className="D6_ADS_G1_DivTr" >
              <img src={SkinImage} alt="Image" className="D6_ADS_ImgTr" />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} style={{textAlign:'center'}}>
          <i  className="fas fa-envelope Icon_mail">  Email Us for Internship/Training</i>
</Grid>
      
        </Grid>

      </Container>
    </div>
  );
};

export default Training_cat;
