import '../../MainCSS.css'
import React from 'react'
import What_treat_BgImage from './What_treat_BgImage'
import What_treat_cat from './What_treat_cat'
import W_T_below_img from './W_T_below_img'
 import './What_we_treat.css'
const What_we_treat = () => {
    return (
        <>
            <div className='W_T_Back_img' >  

          <What_treat_BgImage  />  
          <div className='W_T_below_img1' >  
          <W_T_below_img />
          <What_treat_cat />
          </div>
          </div>
        </>
    )
}

export default What_we_treat