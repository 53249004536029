import React ,{useState}from 'react';
import { makeStyles } from '@mui/styles';
import {Grid,Container, Typography,Paper} from '@mui/material';
// import HIcon from '../../../assets/Images/heading-icon.svg';
import HIcon from '../../../assets/Images/logo.png';
import './D6_SL_WS.css'
import Card from '@mui/material/Card';
import Img1 from '../../../assets/Images/Residential_care.jpg'
import Img2 from '../../../assets/Images/OutPatient_Care.jpg'
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AForm from '../../common/AppointmentForm/D6_DASAC_Form'
import { Dialog, DialogContent } from '@mui/material';
import Slide from '@mui/material/Slide';

// import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const D6_SL_WS = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
    return (
            <>
               <Dialog
        open={open}
        onClose={handleClose}
        className='Dialog_CButton'
        disableScrollLock={true}

        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className='icon-close-btn'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Request a service               
          </Typography>
          <AForm/>
        </DialogContent>
      </Dialog>
        <Container fixed className='D6_SL_WS_Cont'>
            <Grid container justify="center" alignItems="center"  className='D6_SL_WS_Grid'>
                <Grid item xs={12} className='D6_SL_WS_Grid1'>
                    <Typography component="h3" className='D6_SL_WS_Grid1_Div_H1'>
                        {/* <span style ={{fontSize:36}} >Only pay for</span><br /> */}
                        FIRST STEP WELLNESS RETREAT         
                                    </Typography>
                                    {/* <Typography component="h3" className='D6_SL_WS_Grid1_Div_H2'>
                        <span style ={{fontSize:36}} >Only pay for</span><br />
                        Evidence based treatment that really works         
                                    </Typography> */}
                        <Typography component="h3" className='D6_SL_WS_Grid1_Div_H3'>
                        {/* <span style ={{fontSize:36}} >Only pay for</span><br /> */}
                        Evidence based treatment that really works         
                                    </Typography>
                </Grid>
            <Grid container className='D6_SL_WS_Grid2_container'>
                <Grid item md={12} xs={12} className='D6_SL_WS_Grid2' >
                    <div className='D6_SL_WS_Grid2_Div'>
                        <div className='D6_SL_WS_Grid2_HDiv'>
                            <div className='D6_SL_WS_HIcon'>
                                <img src={HIcon}/>
                            </div>
                            <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1'>About Us</Typography>
                        </div>
                        <Typography component="p" className='D6_SL_WS_Grid2_Div_P' >
                        The First Step Wellness Retreat is a Multi-Specialty Rehab &amp; Wellness Facility for Alcohol, Drug Addiction and those afflicted with Mental and Psychological Disorders & Conditions based in Noida, Delhi (NCR) India. We have a holistic and eclectic approach towards the treatment for Addiction. Our treatment is the combination of medicine, therapies and activities.        
                        {/* The First Step Rehab is a physiological Rehab & Wellness Facility for Addiction and those afflicted with Mental Disorders based in Noida, Delhi (NCR) India. We have a holistic and eclectic approach towards the treatment for Addiction and Psychosocial disorder. Our treatment is the combination of medicine, practicing Mindfulness therapies. We understand the serious condition of the suffering addicts and their family and offer treatment that changes lives.  
We are dedicated to ensuring that all patients receive the care that they need in an encouraging environment where they can collaborate with treatment professionals in an atmosphere of dignity and respect.
  		Our Reliable & Effective Drug and Alcohol Addiction Recovery program is headed and supported by experienced psychiatrists, counselors & dedicated Spiritual Therapists and driven by motivated staff with years of experience in treating addiction.
		Our integrated Addiction Recovery program is designed under the auspices of the 12 Step Program which is based on the pillars of honesty, open-mindedness, and willingness, With a combination yoga, meditation, CBT and REBT approved by the World Health Organization for Addiction Treatment. 

		First step wellness center’s holistic treatment aims at  their physical, psychological, emotional, social and spiritual ailments towards total Recovery. Means we are dedicated to healing the whole self. Restoring a person’s neurochemistry is an essential part of our successful treatment. We provide affordable luxury and recovery in a setting where lasting health and peace of mind are the goals. Wellness is so much more than just physical well-being. It`s a balance in mind, body, and sprit. */}

                                        </Typography>
                    </div>
                </Grid>
                <Grid item md={12} xs={12} className='D6_SL_WS_Grid2' >
                    <div className='D6_SL_WS_Grid2_Div'>
                        <div className='D6_SL_WS_Grid2_HDiv'>
                            <div className='D6_SL_WS_HIcon'>
                                <img src={HIcon}/>
                            </div>
                            <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1'>Our Mission</Typography>
                        </div>
                        <Typography component="p" className='D6_SL_WS_Grid2_Div_P' >
                        {/* “We are committed to supporting, educating and promoting recovery for individuals and families struggling with substance use disorders.”
Our mission to educate the community and remove the stigma related to substance abuse and mental health disorder that can stand a barrier at every stage of long term recovery */}
“We are committed to supporting, educating and promoting recovery for individuals and families struggling with substance use disorders.”
Our mission to educate the community and remove the stigma related to substance abuse and mental health disorder that can stand a barrier at every stage of long term recovery. We are committed to life-changing care and creating a personalized treatment plan for you or your loved one.

                        </Typography>
                    </div>
                </Grid>
                <Grid item md={12} xs={12} className='D6_SL_WS_Grid2' >
                    <div className='D6_SL_WS_Grid2_Div'>
                        <div className='D6_SL_WS_Grid2_HDiv'>
                            <div className='D6_SL_WS_HIcon'>
                                <img src={HIcon}/>
                            </div>
                            <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1'>Facilities</Typography>
                        </div>
                        <Typography component="p" className='D6_SL_WS_Grid2_Div_P' >
                        {/* Aatm Avlokan treatment center is designed to be a comfortable, inviting, and intimate healing environment for our clients.  Our facilities feature updated amenities, with attached bathrooms, on-site laundry, dining and lobby area with facilities like Gym and Indoor games.
                        At our wellness center all meals and snacks are prepared in our kitchen and are shared in family style.  Ours wellness retreat for people who seek confidentiality and comfort with 24x7 security with CCTV. */}
                       First step rehab center is designed in a comfortable, inviting, and intimated healing environment for our clients. 
                       Our facilities include updated amenities, with attached bathrooms, on-site laundry, dining area and lobby area. At our wellness center all meals and snacks are prepared in our own kitchen and are served in homely environment. We offer all homely comforts like AC and TV. We are committed to provide 24*7 securities with CCTV.

                        </Typography>

                        {/* <ul className="ul_online">
                          <li
                              component="p"
                              className="D6_ADS_G1_DivP_Online_B"
                            >
                              Schizophrenia 
                              </li>
                          <li  component="p"
                                          className="D6_ADS_G1_DivP_Online_B">
                          Anxiety disorders 
                          </li>
                          </ul> */}
                    </div>
                </Grid>
                </Grid>
                

                
            </Grid>
        </Container>

        <Container style={{maxWidth:'100%'}}>
        <Grid container justify="center" alignItems="center" fixed className="Dec6_Industries_B2B_GridS"   >
               
                   
                    <Grid item sm={6}  xs={12} className="Dec6_Industries_B2B_Grid1">
                        <Container className="Dec6_Industries_B2B_Cont_1">
                        <Card className='card_2'>
      <CardActionArea>
        <CardMedia onClick={()=>window.location.pathname="/Services/Residential-care"} 
          component="img"
          height="240"
          image={Img1}
          alt="green iguana"
        />
        <CardContent onClick={()=>window.location.pathname="/Services/Residential-care"} className='card_color'>
          <Typography  gutterBottom variant="h5" component="div">
          RESIDENTIAL CARE
          </Typography>
          <Typography className='card_text' variant="body2" color="text.secondary">
          Residential recovery programs, also known as Inpatient treatment, require patients to check themselves into a controlled environment to overcome their addictions. Patients stay at a clinic with 24-hour medical and emotional support
          </Typography>
          <Typography className='card_text' variant="body2" color="text.secondary">
          During inpatient treatment, residents are able to completely focus on getting well and sober without the distractions of everyday life. A typical day in residential treatment is carefully scheduled and accounted for. Psychologists, counselors, and psychiatrists meet with patients individually and in group settings to guide inpatient recovery. A typical inpatient program runs anywhere from 28 days to 6 months.
          </Typography>
       
       
        </CardContent>
        <Grid container className='Grid_Chip' xs={12} sm={12} >
          <Chip onClick={handleClickOpen} label="Request for service"  className='chip_button' />
          <Chip onClick={()=>window.location.href="tel:+91 (0) 9999361401"} label="Call Us"  className='chip_button_P1' />
          </Grid>
      </CardActionArea>
    </Card>
                        </Container>
                        </Grid>
                        <Grid item sm={6}  xs={12} className="Dec6_Industries_B2B_Grid1">
                        <Container className="Dec6_Industries_B2B_Cont_1">
                        <Card className='card_2'>
      <CardActionArea>
        <CardMedia onClick={()=>window.location.pathname="/Services/Outpatient-care"} 
          component="img"
          height="240"
          image={Img2}
          alt="green iguana"
        />
        <CardContent onClick={()=>window.location.pathname="/Services/Outpatient-care"} className='card_color'>
          <Typography gutterBottom variant="h5" component="div">
          OUTPATIENT CARE
          </Typography>
          <Typography className='card_text' variant="body2" color="text.secondary">
          Outpatient drug rehab is less restrictive than inpatient programs. Outpatient recovery programs usually require 10 to 12 hours a week spent visiting a local treatment center.
          </Typography>
          <Typography className='card_text' variant="body2" color="text.secondary">
          Patients with mild-to-moderate drug withdrawal symptoms might find outpatient detoxification a fitting alternative to residential detox. Outpatient detox is safe, effective, and takes less time to complete than inpatient detox; the average outpatient detox is 6.5 days.
          </Typography>
          <Typography className='card_text' variant="body2" color="text.secondary">
          Patients must visit a hospital or other treatment facility for physical and mental check-ups during outpatient detox. Clinicians or doctors might administer medications on-site to soothe withdrawal symptoms like anxiety, depression
          </Typography>
       
        </CardContent>
        <Grid container className='Grid_Chip' xs={12} sm={12} >
          <Chip onClick={handleClickOpen} label="Request for service"  className='chip_button' />
          <Chip onClick={()=>window.location.href="tel:+91 (0) 9999361401"} label="Call Us"  className='chip_button_P1' />
          </Grid>
      </CardActionArea>
    </Card>
                        </Container>
                        </Grid>
            
                        
                    </Grid>
                  
        </Container>
        <Grid container spacing={1} style={{padding:'10px'}}>
        <Grid  item  sm={3} xs={6}>
        <Paper onClick={()=>window.location.pathname="/What-we-treat/Alcohol-addiction-treatment"} elevation={3} className='paper_home'  >
            <Grid sm={12} style={{paddingTop:'40px'}} xs={12} >
            <i  className="fas fa-briefcase-medical Icon_home"></i>
            </Grid>
            <Grid sm={12} className='grid_text' xs={12} >ALCOHOL ADDICTION TREATMENT</Grid>
            </Paper>
        </Grid>
            <Grid  item  sm={3} xs={6}>
        <Paper onClick={()=>window.location.pathname="/What-we-treat/Drug-addiction-treatment"} elevation={3} className='paper_home'  >
            <Grid sm={12} style={{paddingTop:'40px'}} xs={12} >
            <i  className="fas fa-syringe Icon_home"></i>
            </Grid>
            <Grid sm={12} className='grid_text' xs={12} >DRUG ADDICTION TREATMENT</Grid>
            </Paper>
        </Grid>     
        <Grid  item  sm={3} xs={6}>
        <Paper onClick={()=>window.location.pathname="/What-we-treat/Psychiatric-conditions"} elevation={3} className='paper_home'  >
            <Grid sm={12} style={{paddingTop:'40px'}} xs={12} >
            <i  className="fas fa-brain Icon_home"></i>
            </Grid>
            <Grid sm={12} className='grid_text' xs={12} >PSYCHIATRIC TREATMENT</Grid>
            </Paper>
        </Grid>
        <Grid  item  sm={3} xs={6}>
        <Paper onClick={()=>window.location.pathname="/Services/Training/Internship"} elevation={3} className='paper_home'  >
            <Grid sm={12} style={{paddingTop:'40px'}} xs={12} >
            <i  className="fas fa-user-md Icon_home"></i>
            </Grid>
            <Grid sm={12} className='grid_text' xs={12} >TRAINING / INTERNSHIP</Grid>
            </Paper>
        </Grid>
        
        </Grid>

       
        </>
    );

};

export default D6_SL_WS;
