import React from 'react'
import { makeStyles } from "@mui/styles";
import {Grid,Container, Typography} from '@mui/material'
// import SkinImage from '../../../assets/Images/heading-icon.svg'
import Img1 from '../../../assets/Images/Rakesh1.jpg'
import Img2 from '../../../assets/Images/Nitin.jpeg'
import Img3 from '../../../assets/Images/Ashima1.jpg'
import Img4 from '../../../assets/Images/Meenu1.jpg'
import '../../../MainCSS.css'
import './D6_DASAC_TS.css'




const D6_DASAC_TS = () => {
    return (
        <div className="spec-container">
        <Container className="D6_TS_Cont">
            <Grid container justify="center" alignItems="center" fixed className="D6_TS_Grid">
                <Grid item xs={12} className="D6_TS_Grid1">
                    <div>
                        <h3 className="D6_TS_GH">
                            Leadership Team
                        </h3>
                    </div>
                </Grid>


                <Grid item md={12} xs={12} className="D6_TS_Grid1">
                    <div className="D6_TS_GDiv">
                        <div className="D6_TS_GDiv_Img" >
                            <img src={Img1} alt="Person1" className="D6_TS_GDiv_ImgS"/>
                        </div>
                        <div className="D6_TS_GDiv_Text" >
                            <h3 className="D6_TS_GDiv_TH" >
                                Rakesh Bhardwaj
                            </h3>
                            <p className="D6_TS_GDiv_TH" >
                            Founder &amp; Wellness Coordinator                           
                            </p>
                            <p className="D6_TS_GDiv_TP" >
                            Mr. Rakesh Bhardwaj, himself has been a part of the recovery community of AA for over 13 years. He is a great source of inspiration and encouragement to people suffering from addictions and alcoholics seeking recovery, as well as and mentor. His passion for helping others achieve their goals, while also loving interacting and communicating with others, is what guided him to this career. 
                            </p>
                            <p className="D6_TS_GDiv_TP" >
                            He truly enjoys motivating, inspiring, and pushing people to be a better than they`ve ever thought- which helps him to do the same for himself.                            
                            </p>

                        </div>
                    </div>
                </Grid>

                <Grid item md={12} xs={12} className="D6_TS_Grid1">
                    <div className="D6_TS_GDiv" >
                        <div className="D6_TS_GDiv_Img">
                            <img src={Img2} alt="Person1" className="D6_TS_GDiv_ImgS"/>
                        </div>
                        <div className="D6_TS_GDiv_Text" >
                            <h3 className="D6_TS_GDiv_TH" >
                                Nitin Lavania
                            </h3>
                            <p className="D6_TS_GDiv_TH" >
                            Co Founder &amp; wellness coach             
                            </p>
                            <p className="D6_TS_GDiv_TP" >
                            Mr. Nitin Lavania  (M.B.A from F.M.S) has extensive experience in management and optimization techniques associated with Dr. Ashish Deshpande the renounced psychiatrist as a 12 step facilitator for those who have drug and alcohol related issues. The majority of his experience has been in General service office of Alcoholics Anonymous India, the highest office of Alcoholics Anonymous in India.                             
                            </p>
                            <p className="D6_TS_GDiv_TP" >
                            He is experienced in implementing in program Recovery of Alcoholics Anonymous and a popular speaker on de-addiction. He himself a recovering alcoholic, he instills and inspires the hope in addicts that they too can recover from Addiction and look forward to a fruitful life.                              
                            </p>
                            <p className="D6_TS_GDiv_TP" >
                            He is an ISSUP certified counselor and coming up with personalized modules for individual therapy.
                            </p>
                        </div>
                    </div>
                </Grid>


              
                <Grid item md={12} xs={12} className="D6_TS_Grid1">
                    <div className="D6_TS_GDiv">
                        <div className="D6_TS_GDiv_Img" >
                            <img src={Img3} alt="Person1" className="D6_TS_GDiv_ImgS"/>
                        </div>
                        <div className="D6_TS_GDiv_Text" >
                            <h3 className="D6_TS_GDiv_TH" >
                                Dr. Ashima Ranjan
                            </h3>
                            <p className="D6_TS_GDiv_TH" >
                                Consultant Psychiatry - M.B.B.S, D.P.M, D.N.B (Psychology &amp; Psychiatry)
                            </p>
                            <p className="D6_TS_GDiv_TP" >
                            Dr. Ashima Ranjan is one of the well-known Psychiatrist and Neuropsychiatrist practicing for the last 13 years.
                             She completed DPM (Psychiatry) from Kasturba Medical College in 2013, MBBS from Kasturba medical college, 
                             Mangalore, Manipal University, Karnataka in 2010 and DNB-Psychiatry from IMH,
                              (Indian Institute of Mental Hospital) Amritsar in 2015.                            
                            </p>
                            <p className="D6_TS_GDiv_TP" >
                            She is responsible for the Detox of our clients and ensures a safe and speedy recovery. 
                            She has extensive experience and exposure in dealing with general and de addiction psychiatry. 
                            She is also an expert in Dual-Diagnosis. She has a strong belief in the rehabilitation of people.
                            </p>
                            <p className="D6_TS_GDiv_TP" >
                            She has extensive experience and exposure in dealing with general and de addiction psychiatry. 
                            She is also an expert in Dual-Diagnosis. She has a strong belief in the rehabilitation of people in all spheres of life. 
                            She is passionate about her work and takes extra steps to ensure overall psychiatric wellbeing of all her clients. 
                            </p>
                        </div>
                    </div>
                </Grid>

                <Grid item md={12} xs={12} className="D6_TS_Grid1">
                    <div className="D6_TS_GDiv" >
                        <div className="D6_TS_GDiv_Img">
                            <img src={Img4} alt="Person1" className="D6_TS_GDiv_ImgS"/>
                        </div>
                        <div className="D6_TS_GDiv_Text" >
                            <h3 className="D6_TS_GDiv_TH" >
                               Dr. Meenu Sharma
                            </h3>
                            <p className="D6_TS_GDiv_TH" >
                                Consultant Psychologist
                            </p>
                            <p className="D6_TS_GDiv_TP" >
                            She is Masters in Psychology from Aligarh Muslim University, Aligarh, and Ph.D. in psychology from 
                            Galgotias University Grater Noida, Practicing in the field for more than 10 years. 
                            In the course of her practice, 
                            she has conducted various workshops and Seminars on Mental Health and Lifestyle related issues.
                            </p>
                            <p className="D6_TS_GDiv_TP" >
                            She helps people who struggle with childhood disorders like Autism, ADHD, learning disabilities, 
                            behavioral issues, and mental illness issues like 
                            Schizophrenia, Bipolar, OCD, Depression, Adjustment issues, anxiety, and Addiction disorder.
                            </p>
                            <p className="D6_TS_GDiv_TP" >
                            She is also actively involved in conducting individual sessions, group therapy sessions for clients and 
                            group family counselling sessions. The dynamic approaches to her evidence based therapeutic framework 
                            ranges from Behavior therapy, CBT, DBT, REBT, MET; 
                            mindfulness based cognitive management, and other supportive psychotherapies.
                            </p>
                        </div>
                    </div>
                </Grid>


                {/* <Grid item md={12} xs={12} className="D6_TS_Grid1">
                    <div className="D6_TS_GDiv">
                        <div className="D6_TS_GDiv_Img" >
                            <img src={SkinImage} alt="Person1" className="D6_TS_GDiv_ImgS"/>
                        </div>
                        <div className="D6_TS_GDiv_Text" >
                            <h3 className="D6_TS_GDiv_TH" >
                            Sanjeev Dutta
                            </h3>
                            <p className="D6_TS_GDiv_TP" >
                            Recovery Coach
                            </p>
                        </div>
                    </div>
                </Grid> */}

               


            </Grid>
        </Container>

        </div>
    )
}

export default D6_DASAC_TS
