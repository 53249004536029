import React from 'react'
import Training_below_img from './Training_below_img'
import Training_BgImage from './Training_BgImage'
import Training_cat from './Training_cat'
import './Training_care.css'
const Training_care = () => {
    return (
        <>
                         <div className='Training_BG'>

          <Training_BgImage  />  
          <Training_below_img/>
          </div>

          <Training_cat/>
        </>
    )
}

export default Training_care
