import Header from './modules/common/Header/D6_SL_Header';
import Drawer from './modules/common/Header/D6_SL_Drawer';
import Drawer1 from './modules/common/Header/D6_SL_Drawer1';
import AF from './modules/common/AboveFooter/D6_SL_AF';
import Footer from './modules/common/Footer/D6_SL_Footer';
import ScrollNPT from './modules/common/Scroll_NewPageTop/D6_DASAC_SNPT';

import {Route, Switch,BrowserRouter} from "react-router-dom";
import Home from './modules/HomePage/D6_SL_Home';
import AboutUs from './modules/AboutPage/D6_DASAC_AboutUs';
import Services from './modules/ServicesPage/Services';
import Contact from './modules/ContactPage/D6_DASAC_Contact';
import What_we_treat from './modules/What_Treat/What_we_treat';
import Inpatient_care from './modules/ServicesPage/Services/Inpatient_care/Inpatient_care';
import Outpatient_care from './modules/ServicesPage/Services/Outpatient_care/Outpatient_care';
import Online_care from './modules/ServicesPage/Services/Online_patent_care/Online_care';
import Psy_care from './modules/ServicesPage/Services/Psy_care/Psy_care';
import Family_support from './modules/ServicesPage/Services/Family_support/Family_support_';
import After_care from './modules/ServicesPage/Services/After_care/After_care';
import Community_care from './modules/ServicesPage/Services/Community/community_care';
import Training_care from './modules/ServicesPage/Services/Training_Internship/Training_care';
import STTop from './modules/common/Scroll_To_Top/Dec6_STTop';
import PhoneTop from './modules/common/Phone_on_Top/Dec6_PhoneTop';
import Alcohol_addic from './modules/What_Treat/Alcohol_addic/Alcohol_addic';
import Drug_addict from './modules/What_Treat/Drug_addic/Drug_addic';
import Dual_addict from './modules/What_Treat/Dual_diagnosis/Dual_addic';
import Psych_addic from './modules/What_Treat/Psychiatric_conditions/Psych_addic';
import Gallery from './modules/Gallery/Gallery';
import Blog from './modules/Blog/Blog';
import Blog1 from './modules/Blog/Blog1/Blog1';
import Blog2 from './modules/Blog/Blog2/Blog2';
import Blog4 from './modules/Blog/Blog4/Blog4';
import Blog5 from './modules/Blog/Blog5/Blog5';
import Blog6 from './modules/Blog/Blog6/Blog6';
import Blog3 from './modules/Blog/Blog3/Blog3';


function App() {
  return (
    <>
    
    <BrowserRouter> 
    <ScrollNPT/> 
      <Header/> 

            <Route exact path="/" component={Home} /> 
            <Route exact path="/AboutUs" component={AboutUs} />
            <Route exact path="/Services" component={Services} />   
            <Route exact path="/ContactUs" component={Contact} />  
            <Route exact path="/What-we-treat" component={What_we_treat} />  
            <Route exact path="/Services/Residential-care" component={Inpatient_care} />  
            <Route exact path="/Services/Outpatient-care" component={Outpatient_care} />  
            <Route exact path="/Services/Onlinepatient-care" component={Online_care} />  

            <Route exact path="/Services/Psychological-care" component={Psy_care} />  
            <Route exact path="/Services/Family-support" component={Family_support} />  
            <Route exact path="/Services/After-care" component={After_care} />  
            <Route exact path="/Services/Community" component={Community_care} />  
            <Route exact path="/Services/Training/Internship" component={Training_care} /> 
            <Route exact path="/What-we-treat/Alcohol-addiction-treatment" component={Alcohol_addic} /> 

            <Route exact path="/What-we-treat/Drug-addiction-treatment" component={Drug_addict} /> 
            <Route exact path="/What-we-treat/Dual-diagnosis" component={Dual_addict} /> 

            <Route exact path="/What-we-treat/Psychiatric-conditions" component={Psych_addic} /> 

            {/* <Route exact path="/Gallery" component={Gallery} /> 

            <Route exact path="/Blogs" component={Blog} /> 
            <Route exact path="/Blogs/Blog1" component={Blog1} /> 
            <Route exact path="/Blogs/Blog2" component={Blog2} /> 
            <Route exact path="/Blogs/Blog3" component={Blog3} /> 
            <Route exact path="/Blogs/Blog4" component={Blog4} />            
            <Route exact path="/Blogs/Blog5" component={Blog5} /> 
            <Route exact path="/Blogs/Blog6" component={Blog6} />  */}


<STTop />
{/* <PhoneTop/> */}
      <AF/>
      <Footer/> 
    </BrowserRouter>  
    </>
  );
}

export default App;
