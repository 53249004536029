import React from 'react';
import './Inpatient_cat_CSS.css';
import '../../../../MainCSS.css';
import {Paper,Container,Typography,Box,Grid} from "@mui/material";
import HIcon from '../../../../assets/Images/logo.png';
import Img1 from '../../../../assets/Images/Residential_care.jpg';

const Dec6_Services_GPC_Cont_Typ1_Style = {
    fontSize : '1.5rem',
    paddingTop : '70px'
}

function Inpatient_care() {
    return(
    /*    <div className="Dec6_Services_GPC_MainBlock_bg">
        <div className="Dec6_Services_GPC_MainBlock">     */

    <Container className='W_T_cont1'>
    <Grid container justify="center" alignItems="center" fixed className="Dec6_Services_GPC_Grid_I">

    <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_Grid_Online"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1_Online">
            <div className="D6_ADS_G1_Div_Online1">
              {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                AbOnline RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDiv_Online'>
                <div className='D6_SL_WS_HIcon_Online'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1_Online'>RESIDENTIAL CARE</Typography>
            </div>
            <Typography className='D6_ADS_G1_DivP_Online_B'>
            RESIDENTIAL CARE is also known as INPATIENT CARE. Inpatient care is a residential treatment program, 
            where patients reside for various lengths depending on several factors, including the severity of the addiction.
            </Typography>
            <Typography className='D6_ADS_G1_DivP_Online_B'>
            In addition, we offer plenty of activities throughout the day that foster healthy expression and connection with others, 
            such as art and recreational therapy, health and wellness groups, and 12-step meetings both on &amp; off site.
            </Typography>

          {/* <Grid item xs={12} sm={6} className="D6_ADS_Grid1">
            <div className="D6_ADS_G1_Div" >
              <img src={Img1} alt="Image" className="D6_ADS_Img" />
            </div>
          </Grid> */}

            <Typography className='D6_ADS_G1_DivP_Online_B'>Each client is exposed to all of the following elements: </Typography>

            <ul className="ul_online">
            <li
            style={{listStyleType:'none'}}
                component="p"
                className="D6_ADS_G1_DivP_Online_B"
              >
                </li>
            <li
                component="p"
                className="D6_ADS_G1_DivP_Online_B"
              >
               <b>Detox</b>- withdrawal management under expert round-the-clock medical supervision 
                </li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Psychiatric assessment</b> – by a psychiatrist for any co-occurring or underlying psychiatric issues 
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Daily Yoga and meditation</b> – early morning sessions 
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
 Input sessions</b> – interactive group sessions by several therapists on a variety of relevant topics followed by individual processing 
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Individual counseling</b> – by the assigned primary counselor on a regular basis  
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Emotional management group sessions</b> – practical CBT model for identifying thoughts and emotions  
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Audio-video inputs</b> – viewing and listening to relevant films and audio tapes sourced from all over the world 
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Autobiography writing</b> – writing of the life story and sharing with the counselor/ group 
</li>




<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Working the Steps </b>– writing intensive workbooks on Steps 1, 2 and 3 of Alcoholics Anonymous, Narcotics Anonymous 
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
  Input sessions </b>– interactive group sessions by several therapists on a variety of relevant topics followed by individual processing 

</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Group Feedbacks </b>– getting structured feedback from fellow members</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Family interventions and meetings</b> – helpful in denial breaking, boundary settings and resolving long standing issues including marital and parental aspects</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Psychological intervention </b>– in cases identified with acute co-occurring psychological issue, a clinical psychologist is brought in for in-depth diagnosis and support</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Psychiatric support </b>– for those with dual-diagnosis, regular sessions with the psychiatrist are arranged and becomes a part of the individual’s treatment plan</li>

<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Relapse Prevention </b>– identifying individual relapse triggers and learning how to deal with them 
</li>


<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Family Communication </b>– special sessions prior to discharge aimed at re-building relationships </li>

<li  component="p"
                className="D6_ADS_G1_DivP_Online_B"><b>
Follow-up </b>– Dates fixed for structured follow up online sessions to address ongoing challenges in recovery</li>

            </ul>
              
  
 </div>
          </Grid>
          <Grid item xs={12} sm={6} className="D6_ADS_Grid1">
            <div className="D6_ADS_G1_Div" >
              <img src={Img1} alt="Image" className="D6_ADS_Img" />
            </div>
          </Grid>
         
         
        </Grid>


        {/* <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I1">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Detox
                        </h3>
                        <p className='paragraph_inpatient'>
                        Withdrawal management under expert round-the-clock medical supervision.                             </p>
                      
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I2">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Comprehensive medical assessment                        </h3>
                        <p className='paragraph_inpatient'>
                        For most physical parameters  
                        </p>                     
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I3">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Psychiatric assessment                       </h3>
                        <p className='paragraph_inpatient'>
                        By a psychiatrist for any co-occurring or underlying psychiatric issues                        </p>
                    </Box>
                </Paper>
            </Container>
        </Grid>
        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I1">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Daily Yoga and meditation                        </h3>
                        <p className='paragraph_inpatient'>
                        Early morning sessions                         </p>
                      
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I2">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Input sessions                      </h3>
                      <p className='paragraph_inpatient'>
                      Interactive group sessions by several therapists on a variety of relevant topics followed by individual processing                     </p>
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I3">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Individual counseling                         </h3>
                        <p className='paragraph_inpatient'>
                        By the assigned primary counselor on a regular basis                         </p>
                    </Box>
                </Paper>
            </Container>
        </Grid>
        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I1">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Emotional management group sessions                         </h3><p className='paragraph_inpatient'>
                        Practical CBT model for identifying thoughts and emotions                         </p>
                      
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I2">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Audio-video inputs                         </h3>
                      <p className='paragraph_inpatient'>
                      Viewing and listening to relevant films and audio tapes sourced from all over the world                         </p>
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I3">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Autobiography writing                        </h3>
                        <p className='paragraph_inpatient'>
                        Writing of the life story and sharing with the counselor/ group                         </p>
                    </Box>
                </Paper>
            </Container>
        </Grid>  <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I1">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Working the Steps                        </h3><p className='paragraph_inpatient'>
                        Writing intensive workbooks on Steps 1, 2 and 3 of Alcoholics Anonymous, Narcotics Anonymous and/ or Gamblers Anonymous                        </p>
                      
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I2">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Group Feedbacks                         </h3>
                      <p className='paragraph_inpatient'>
                      Getting structured feedback from fellow members  
                        </p>
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I3">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Family interventions and meetings                        </h3>
                        <p className='paragraph_inpatient'>
                        Helpful in denial breaking, boundary settings and resolving long standing issues including marital and parental aspects  
                        </p>
                    </Box>
                </Paper>
            </Container>
        </Grid>
        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I1">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Psychological intervention                        </h3><p className='paragraph_inpatient'>
                        In cases identified with acute co-occurring psychological issue, a clinical psychologist is brought in for in-depth diagnosis and support                         </p>
                      
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I2">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Psychiatric support                       </h3>
                      <p className='paragraph_inpatient'>
                      For those with dual-diagnosis, regular sessions with the psychiatrist are arranged and becomes a part of the individual’s treatment plan                         </p>
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_I3">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Relapse Prevention                        </h3>
                        <p className='paragraph_inpatient'>
                        Identifying individual relapse triggers and learning how to deal with them                         </p>
                    </Box>
                </Paper>
            </Container>
        </Grid>
          <Grid item lg={6} sm={6} md={6} xs={12} className="Dec6_Services_GPC_Grid_I1">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Family Communication 
                        </h3><p className='paragraph_inpatient'>
                        Special sessions prior to discharge aimed at re-building relationships                         </p>
                      
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={6} sm={6} md={6} xs={12} className="Dec6_Services_GPC_Grid_I2">
            <Container className="Dec6_Services_GPC_Cont1_I">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_I_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_I_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_I_Paper_Box_H">
                        Follow-up                  </h3>
                      <p className='paragraph_inpatient'>
                      Dates fixed for structured follow up online sessions to address ongoing challenges in recovery                        </p>
                    </Box>
                </Paper>
            </Container>
        </Grid> */}

       
 

    </Grid>
    </Container>
    );
}

export default Inpatient_care;