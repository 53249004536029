import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@mui/material";
import SkinImage from "../../../../assets/Images/Family_Care.jpg";
 import HIcon from '../../../../assets/Images/logo.png';
import "../../../../MainCSS.css";
import "./Family_cat.css"
const Fam_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_Cont_Fam">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_Grid_Fam"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1_Fam">
            <div className="D6_ADS_G1_Div_Fam">
              {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                AbFam RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDiv_Fam'>
                <div className='D6_SL_WS_HIcon_Fam'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1_Fam'>FAMILY SUPPORT PROGRAM  </Typography>
            </div>
            <Typography
                component="p"
                className="D6_ADS_G1_DivP_Fam_B"
              >
{/* The Aatm Avlokan's family support Program is focused on understanding the disease of addiction and how it affects the family. Helping a loved one recover from addiction isn't just a matter of providing encouragement or moral support. While your support is crucial, you must also be prepared to get actively involved in the healing process recovery. */}
Our family support Program is focused on understanding the disease of addiction and how it affects the family. Helping a loved one recover from addiction isn’t just a matter of providing encouragement or moral support. While your support is crucial, you must also be prepared to get actively involved in the healing process recovery.
</Typography>
{/* <Typography
                component="p"
                className="D6_ADS_G1_DivP_Fam"
              >
The Hope Family Support Program focuses on understanding the disease of addiction and how it impacts everyone in a family, constructive problem resolution and continuing care for the whole family.  </Typography>

<Typography
                component="p"
                className="D6_ADS_G1_DivP_Fam"
              >
Family members learn about various family dynamics and tools that they can use to build healthy family systems with someone in recovery. In addition, specific information regarding the family member in treatment is incorporated to ensure a lasting recovery for the client and healthy family dynamics.   </Typography>
            */}
  

              
  
 </div>
          </Grid>

          <Grid item xs={12} sm={6} className="D6_ADS_Grid1_Fam">
            <div className="D6_ADS_G1_Div_Fam" >
              <img src={SkinImage} alt="Image" className="D6_ADS_Img_Fam" />
            </div>
          </Grid>



          <Grid item xs={12} position={'center'} sm={12} className="D6_ADS_Grid1_Fam">
            <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1_Fam_list'>The key elements of the FAMILY SUPPORT THERAPY PROGRAM includes : </Typography>

          </Grid>
          <Grid item sm={12} xs={12} className="D6_ADS_Grid1_Fam">
   
            <ul className="ul_Fam">
            <li
                component="p"
                className="D6_ADS_G1_DivP_Fam_B"
              >
                Educating the family about addiction  
                </li>
<li  component="p"
                className="D6_ADS_G1_DivP_Fam_B">
Briefing the family about what to expect from the treatment program and how to cooperate for maximum benefit </li>
<li  component="p"
                className="D6_ADS_G1_DivP_Fam_B">
Introduction to Al-anon family support groups </li>
<li  component="p"
                className="D6_ADS_G1_DivP_Fam_B">
Counseling for personal issues, conflict resolution and stress management </li>
<li  component="p"
                className="D6_ADS_G1_DivP_Fam_B">
Participation in structured intervention sessions with clients in treatment </li>
{/* <li  component="p"
                className="D6_ADS_G1_DivP_Fam_B">
Participation in the client’s relapse prevention plan </li> */}
<li  component="p"
                className="D6_ADS_G1_DivP_Fam_B">
Creating a supportive environment where everyone feels safe to discuss emotionally charged topics</li>
            </ul>

            <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
<span style ={{color: 'green'}} >If your loved one needs drug or alcohol rehab, call us today. 
We can help your family rebalance, stabilize, and achieve a new healthier life.</span>
</Typography>
            <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
Get in touch with us on <span style ={{color: 'green'}} >+91999936140</span>
</Typography>    
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Fam_cat;
