import React from 'react'
import {Link} from '@mui/material'
import './D6_SL_Footer_CSS.css'

const D6_SL_Footer = () => {
    return (
        <div className="D6_SL_Footer_Items">
            {/* <div className="D6_SL_Footer_Items_NavLink D6_SL_Footer_Items_NavLink1">
                    <Link className="D6_SL_Footer_Items_Link" exact to="/">
                            HOME
                    </Link>
                </div>
                <div className="D6_SL_Footer_Items_NavLink D6_SL_Footer_Items_NavLink2">
                    <Link className="D6_SL_Footer_Items_Link" exact to="/">
                            PRIVACY POLICY
                    </Link>
                </div>
                <div className="D6_SL_Footer_Items_NavLink D6_SL_Footer_Items_NavLink3">
                    <Link className="D6_SL_Footer_Items_Link" exact to="/">
                            TERMS &amp; CONDITIONS
                    </Link>
                </div> */}
                <div className="D6_SL_Footer_Items_P_Div">  
                    <p className="D6_SL_Footer_Items_P">
                        &#169;2022 First Step Wellness
                    </p>
                </div>
        </div>
    )
}

export default D6_SL_Footer
