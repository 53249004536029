import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@mui/material";
import SkinImage from "../../../../assets/Images/OutPatient_Care.jpg";
 import HIcon from '../../../../assets/Images/logo.png';
import "../../../../MainCSS.css";
import "./Out_cat.css"
const Out_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_Cont">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_Grid"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1">
            <div className="D6_ADS_G1_Div">
              {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                About RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDiv'>
                <div className='D6_SL_WS_HIcon'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1'>OUTPATIENT CARE</Typography>
            </div>
              <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
               Outpatient program follows the same principles as the highly rated Residential Care and provides individualized focus within the program structure. Therapeutic measures comprise of one-to-one counseling and 12 Steps facilitation. 
</Typography>
<Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
We have a highly skilled TEAM of addiction therapists, clinical psychologists, and psychiatrists to address a variety of substance abuse, psychological and psychiatric issues.
</Typography>

<Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
Psychiatric intervention available for the issue including
</Typography>
<ul className="ul_dual">
    <li className="li_dual">Schizophrenia</li>
  <li className="li_dual">Anxiety disorders</li>
  <li className="li_dual">Bipolar affective disorder</li>
  <li className="li_dual">Depression</li>
  <li className="li_dual">Sleep-related issues</li>
  <li className="li_dual">Obsessive Compulsive Disorder (OCD)</li>
  <li className="li_dual">Personality disorder</li>
  <li className="li_dual">Mental Health</li>
  {/* <li className="li_dual">Dialectical behavioral therapy (DBT)</li>
  <li className="li_dual">Family support Program</li>
  <li className="li_dual">Follow-ups</li> */}
  </ul>      
  
 </div>
          </Grid>

          <Grid item xs={12} sm={6} className="D6_ADS_Grid1">
            <div className="D6_ADS_G1_Div" >
              <img src={SkinImage} alt="Image" className="D6_ADS_Img" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Out_cat;
