import React from 'react';
import './Inpatient_care_below_img.css';

function Inpatient_care_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_I">
            <h3 className="Dec6_Services_WCU_Cont_H_I">
            RESIDENTIAL CARE
            </h3>
            <p className="Dec6_Services_WCU_Cont_P_I">
            {/* “Inpatient programs offer the highest level of treatment care, with medically supervised detoxification and around-the-clock care and support.” */}
            {/* RESIDENTIAL CARE is also known as INPATIENT CARE. Inpatient care is a residential treatment program, where patients reside for various lengths depending on several factors, including the severity of the addiction. */}
            Residential treatment for Addiction and Dual Diagnosis and Mental Illness
            </p>
            {/* <p className="Dec6_Services_WCU_Cont_P_I">
            Treatment at FIRST STEP REHAB is made up of a day-to-day schedule of therapeutic services and activities. 
            The day begins at 6 am, with programming ending at 8:30 pm. Your loved one will attend groups and individual therapy sessions driven by licensed therapists. Much of this time is spent helping your 
            loved one learn about the disease of addiction as well as any co-occurring disorders they may have.
            </p> */}
            {/* <p className="Dec6_Services_WCU_Cont_P_I">
            In addition, we offer plenty of activities throughout the day that foster healthy expression and connection with others, 
            such as art and recreational therapy, health and wellness groups, and 12-step meetings both on- and off site.
            </p> */}
                        <i className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_I"></i>
        </div>
    );
}

export default Inpatient_care_below_img;