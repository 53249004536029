import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@mui/material";
import SkinImage from "../../../assets/Images/drug_acc.jpg";
import HIcon from '../../../assets/Images/logo.png';
import "../../../MainCSS.css";
import "./Drug_cat.css"
const Drug_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_ContDr">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_GridDr"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1Dr">
            <div className="D6_ADS_G1_DivDr">
              {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                AbFam RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDivDr'>
                <div className='D6_SL_WS_HIconDr'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1Dr'>DRUG ADDICTION TREATMENT</Typography>
            </div>
            <Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
Drug addiction is a powerful, overwhelming force. For those who suffer from drug addiction, they often find deterioration in their health, behaviors, and decision-making abilities. For most people, drug addiction develops over time until it is both a mental and physical addiction. Overcoming both of these issues without professional help is impossible for many people. That’s where our drug detox and rehabilitation programs can help.
</Typography>
<Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
If you’re struggling with drug addiction, whether it is to Cocaine, Heroin, Marijuana, Methamphetamines, or another substance, you’ve likely experienced the ravages of addiction. Fortunately, you’re not alone--
</Typography>
<Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
First Step Wellness center is here to help you achieve lifelong recovery and safely navigate the drug detox process. Our drug detox program addresses all elements of detoxification and withdrawal, ensuring that each of our clients is nurtured mind, body and soul while undergoing the withdrawal process.
</Typography>

{/* <Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
First Step Wellness center will treat you with the compassion, dignity and respect you deserve, staying by your side through every phase of treatment.
</Typography> */}
<Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
An addiction specialist will outline your individualized drug addiction treatment program. Our thorough process consists of a 
personalized assessment of drug habits, a comprehensive physical to evaluate your overall health, and an individualized detox program. 
This multi-channel approach is coupled with medical supervision, healthy dietary planning, individual and group therapy, and daily 
yoga and Meditation, organized physical activity, extensive cognitive behavior therapy and 12 step Recovery Program of Narcotics Anonymous
</Typography>
<Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
Begin your recovery journey with Residential Care treatment for drug
</Typography>
<Typography
                component="p"
                className="D6_ADS_G1_DivPAl_B"
              >
<span style ={{color: 'green'}} >Schedule a private and confidential treatment</span>
</Typography>


              
  
 </div>
          </Grid>

          <Grid item xs={12} sm={6} className="D6_ADS_Grid1Dr">
            <div className="D6_ADS_G1_DivDr" >
              <img src={SkinImage} alt="Image" className="D6_ADS_ImgDr" />
            </div>
          </Grid>
       
        
      
        </Grid>

      </Container>
    </div>
  );
};

export default Drug_cat;
