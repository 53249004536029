import React from 'react'
import Community_below_img from './community_below_img'
import Community_care_BgImage from './community_care_BgImage'
import Community_cat from './community_cat'
import './community_care.css'
const Community_care = () => {
    return (
        <>
                         <div className='community_BG'>

          <Community_care_BgImage  />  
          <Community_below_img/>
          </div>
          <Community_cat/>
        </>
    )
}

export default Community_care
