import React from 'react'
import Drug_below_img from './Drug_below_img'
import Drug_BgImage from './Drug_BgImage'
import Drug_cat from './Drug_cat'
import './Drug_addic.css'
 export default function Drug_addict(){
     return(
<>
<div className='Online_BG'>

<Drug_BgImage/>
<Drug_below_img/>
</div>
<Drug_cat/>
</>
     )
 }