import React from 'react'
import Alcohol_below_img from './Alcohol_below_img'
import Alcohol_BgImage from './Alcohol_BgImage'
import Alcohol_cat from './Alcohol_cat'
import './Alcohol_addic.css'
 export default function(){
     return(
<>
<div className='Alcohol_BG'>

<Alcohol_BgImage/>
<Alcohol_below_img/>
</div>
<Alcohol_cat/>
</>
     )
 }