import React from 'react';
import './Online_care_below_img.css';
import "../../../../MainCSS.css";


function Online_care_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_I_Out">
            <h3 className="Dec6_Services_WCU_Cont_H_I_Out">
            ONLINE PATIENT CARE
            </h3>
            <p className="Dec6_Services_WCU_Cont_P_I_Out">
            First step's online care - HELP IS JUST A CLICK AWAY! 
            </p>
            {/* <p className="Dec6_Services_WCU_Cont_P_I_Out">
            Online counseling is a newest and a productive way to reach out to the therapist to find out the solutions for addiction, psychological or psychiatric problems-from where you may be. 
            </p>
            <p className="Dec6_Services_WCU_Cont_P_I_Out">
            “Lot of us have certain specific issues which may not be comfortable to share face to face with someone friend, spouse or therapist -may be because of geographical or social stigma.”
            </p> */}
            <i className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_I_Out"></i>
        </div>
    );
}

export default Online_care_below_img;