import React from 'react'
import After_care_below_img from './After_care_below_img'
import After_care_BgImage from './After_care_BgImage'
import After_cat from './After_cat'
import './After_care.css'
const After_care = () => {
    return (
        <>
                         <div className='Online_BG'>

          <After_care_BgImage  />  
          <After_care_below_img />
          </div>

          <After_cat />
        </>
    )
}

export default After_care
