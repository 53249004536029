import React from 'react'
import Outpatient_care_BgImage from './Outpatient_care_BgImage'
import Outpatient_care_below_img from './Outpatient_care_below_img'
import Out_cat from './Out_cat'
import './Outpatient_care.css'
const Outpatient_care = () => {
    return (
        <>
                 <div className='Outpatient_BG' > 
          <Outpatient_care_BgImage  />  

          <Outpatient_care_below_img />
          </div>
          <Out_cat />
        </>
    )
}

export default Outpatient_care
