import React from 'react';
import './What_treat_cat_CSS.css';
import '../../MainCSS.css';
import {Paper,Container,Typography,Box,Grid} from "@mui/material";


const Dec6_Services_GPC_Cont_Typ1_Style = {
    fontSize : '1.5rem',
    paddingTop : '70px'
}

function What_treat_cat() {
    return(
    /*    <div className="Dec6_Services_GPC_MainBlock_bg">
        <div className="Dec6_Services_GPC_MainBlock">     */

    <Container className='W_T_cont'>
    <Grid container justify="center" alignItems="center" fixed className="Dec6_Services_GPC_Grid">
        <Grid item lg={6} sm={6} md={6} xs={12} className="Dec6_Services_GPC_Grid1">
            <Container className="Dec6_Services_GPC_Cont1">
                <Paper onClick={()=>window.location.pathname="/What-we-treat/Alcohol-addiction-treatment"} elevation={14} className="Dec6_Services_GPC_Cont1_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_H">
                            ALCOHOL ADDICTION
                        </h3>
                        {/* <div className="Dec6_Services_GPC_Cont1_Content">
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_ITCons_Text[0]}
                            </h3> 
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_ITCons_Text[1]}
                            </h3>
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_ITCons_Text[2]}
                            </h3>        
                        </div> */}
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={6} sm={6} md={6} xs={12} className="Dec6_Services_GPC_Grid2">
            <Container className="Dec6_Services_GPC_Cont1">
                <Paper onClick={()=>window.location.pathname="/What-we-treat/Drug-addiction-treatment"} elevation={14} className="Dec6_Services_GPC_Cont1_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_H">
                        DRUG ADDICTION                        </h3>
                        {/* <div className="Dec6_Services_GPC_Cont1_Content">
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_Digi_Text[0]}
                            </h3> 
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_Digi_Text[1]}
                            </h3>
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_Digi_Text[2]}
                            </h3>        
                        </div> */}
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={6} sm={6} md={6} xs={12} className="Dec6_Services_GPC_Grid3">
            <Container className="Dec6_Services_GPC_Cont1">
                <Paper onClick={()=>window.location.pathname="/What-we-treat/Dual-diagnosis"} elevation={14} className="Dec6_Services_GPC_Cont1_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_H">
DUAL DIAGNOSIS                        </h3>
                        {/* <div className="Dec6_Services_GPC_Cont1_Content">
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_CybSec_Text[0]}
                            </h3> 
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_CybSec_Text[1]}
                            </h3>
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_CybSec_Text[2]}
                            </h3>        
                        </div> */}
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={6} sm={6} md={6} xs={12} className="Dec6_Services_GPC_Grid4">
            <Container className="Dec6_Services_GPC_Cont1">
                <Paper onClick={()=>window.location.pathname="/What-we-treat/Psychiatric-conditions"}elevation={14} className="Dec6_Services_GPC_Cont1_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_H">
PSYCHIATRIC CONDITIONS                        </h3>
                        {/* <div className="Dec6_Services_GPC_Cont1_Content">
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_ProdInnov_Text[0]}
                            </h3> 
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_ProdInnov_Text[1]}
                            </h3>
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_ProdInnov_Text[2]}
                            </h3>        
                        </div> */}
                    </Box>
                </Paper>
            </Container>
        </Grid>

        {/* <Grid item lg={4} sm={6} md={4} xs={12} className="Dec6_Services_GPC_Grid5">
            <Container className="Dec6_Services_GPC_Cont1">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_H">
                            {Dec6_Services_GPC_Cont1_Typg_H_Text[4]}
                        </h3>
                        <div className="Dec6_Services_GPC_Cont1_Content">
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_SoftEng_Text[0]}
                            </h3> 
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_SoftEng_Text[1]}
                            </h3>
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_SoftEng_Text[2]}
                            </h3>        
                        </div>
                    </Box>
                </Paper>
            </Container>
        </Grid> */}

        {/* <Grid item lg={4} sm={6} md={4} xs={12} className="Dec6_Services_GPC_Grid6">
            <Container className="Dec6_Services_GPC_Cont1">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_H">
                            {Dec6_Services_GPC_Cont1_Typg_H_Text[5]}
                        </h3>
                        <div className="Dec6_Services_GPC_Cont1_Content">
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_Ins_Text[0]}
                            </h3> 
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_Ins_Text[1]}
                            </h3>
                            <h3 className="Dec6_Services_GPC_Cont1_Paper_Box_SubH">
                                {Dec6_Services_GPC_Cont1_List_Ins_Text[2]}
                            </h3>        
                        </div>
                    </Box>
                </Paper>
            </Container>
        </Grid> */}
    </Grid>
    </Container>
    );
}

export default What_treat_cat;