import React,{Component} from 'react'
import axios from 'axios'
import './D6_SL_AF_Form_CSS.css'
import Button from '@mui/material/Button';
import ReactPhoneInput from "react-phone-input-2";
 import 'react-phone-input-2/lib/style.css' 
 import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const D6_SL_AF_Form =()=> {
  const [processing,setProcessing]=React.useState(false)

    const [data,setData]=React.useState({
        name:'',
        email:'',
        phone:'',
        message:''

    })
    const [submitted,setSubmitted]=React.useState(false)
    const handleSubmit=()=>{
        const formData=new FormData
        formData.append('name',data.name)
        formData.append('email',data.email)
        formData.append('phonenumber',data.phone)
        formData.append('message',data.message)
        axios.post('https://d6apicluster.herokuapp.com/mail-aatam', formData)
        .then(response =>MailRes(response.detail))          }

        const MailRes=(val)=>{
setSubmitted(true)
setData({
    ...data,name:'',email:'',phone:'',message:''
})
        }
    const ref=React.useRef()

    return(
    <div className="Form_Div_New">
        <ValidatorForm
                ref={ref}
                onSubmit={handleSubmit}
        >
     
             <div className="TF_div">
                <label className="TF_Label">Name*</label>
                <TextValidator className="PN_Input2"
                    label=""
                    multiLine
                    onChange={(e)=>setData({...data,
                        name:e.target.value
                    })}
                    name="name"
                    value={data.name}
                    validators={['required', 'matchRegexp:[A-Za-z]+']}
                    errorMessages={['Name  required', 'Invalid name']}  
                />  
            </div>
            <div className="TF_div">
              <label className="TF_Label">Email*</label>
              <TextValidator className="PN_Input2"
                      label=""
                      onChange={(e)=>setData({...data,
                        email:e.target.value
                    })}  name="email"
                      value={data.email}
                      validators={['required', 'isEmail']}
                      errorMessages={['Email  required', 'Invalid email']}
                  />
            </div>
           
            <div className="PNDiv2">
              <label className="PN_Label2">Phone Number*</label>
              <ReactPhoneInput className="PN_Input2"
                // style={{color: 'white' }}
                inputProps={{
                  name: 'phonenumber',
                  required: true
                }}
               buttonStyle={{
                   backgroundColor:'black',
                //    color:'black'
               }}
            // containerStyle={{backgroundColor:'black'}}
                dropdownStyle={{
                    color:'black',
                }}
                country={'in'}
                value={data.phonenumber}  
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return 'Invalid value: '+value+', '+country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  }
                  else if (value.match(/1234/)) {
                    return 'Phone number is invalid';
                  }
                   else {
                    return true;
                  }
                }}
                onChange={phonenumber =>setData({...data,phone: phonenumber })}  
              />
            </div>
            <div className="PNDiv2">
                <label className="PN_Label2">Message</label>

            <TextValidator className="PN_Input21"
            name='message'
            multiline
            maxRows={2}
rowsMax={3}            // rowsMax={3}
            
            onChange={(e)=>setData({...data,
                message:e.target.value
            })}       value={data.message}
                />
        </div>
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                     {
                    submitted&&!processing?'Submitted':submitted&&processing?'Submitting':'submit'
                  }
                </Button>       
        </ValidatorForm>
        
    </div>)
}


export default D6_SL_AF_Form
