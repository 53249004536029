import React from 'react'
import Psych_below_img from './Psych_below_img'
import Psych_BgImage from './Psych_BgImage'
import Psych_cat from './Psych_cat'
import './Psych_addic.css'
 export default  function Psych_addic(){
     return(
<>
<div className='Online_BG'>

<Psych_BgImage/>
<Psych_below_img/>
</div>
<Psych_cat/>
</>
     )
 }