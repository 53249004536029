import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@mui/material";
import SkinImage from "../../../../assets/Images/AM_Self.jpg";
import ServicesImage1 from "../../../../assets/Images/online.jpg";
import HIcon from '../../../../assets/Images/logo.png';
import "../../../../MainCSS.css";
import "./Online_cat.css"
const Online_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_Cont_Online">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_Grid_Online"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1_Online">
            <div className="D6_ADS_G1_Div_Online">
              {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                AbOnline RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDiv_Online'>
                <div className='D6_SL_WS_HIcon_Online'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1_Online'>ONLINE CONSULTATION</Typography>
            </div>
            <Typography  className='D6_ADS_G1_DivP_Online_B'>Online counseling is a newest and a productive way to reach out to the therapist to find out the solutions for addiction, psychological or psychiatric problems-from where you may be.
            </Typography>
            <Typography  className='D6_ADS_G1_DivP_Online_B'>“Lot of us have certain specific issues which may not be comfortable to share face to face with someone friend, spouse or therapist -may be because of geographical or social stigma.”
            </Typography>
            <Typography  className='D6_ADS_G1_DivP_Online_B'>Therapeutic technics includes 12 step counseling, CBT and DBT, Online consultation available for issues including :
            </Typography>

            <ul className="ul_online">
            
            <li
                component="p"
                className="D6_ADS_G1_DivP_Online_B"
              >
                Substance use disorder  
                </li>
                <li  component="p"
                className="D6_ADS_G1_DivP_Online_B">
Anxiety disorders 
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B">
 Depression 
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B">
Bipolar affective disorder 
</li>
                <li
                component="p"
                className="D6_ADS_G1_DivP_Online_B"
              >
                Schizophrenia 
                </li>


                <li  component="p"
                className="D6_ADS_G1_DivP_Online_B">
Obsessive Compulsive Disorder (OCD) 
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B">
 Adjustment issues  
</li>

<li  component="p"
                className="D6_ADS_G1_DivP_Online_B">
Behavioral issues 
</li>
<li  component="p"
                className="D6_ADS_G1_DivP_Online_B">
Childhood disorder
</li>
     
            </ul>
            <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
<span style ={{color: 'green'}} >Online counseling yet a way showing nothing is impossible in the path of Recovery.</span>
</Typography>
            <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
 <span style ={{color: 'green'}} >JUST A CLICK AWAY</span>
</Typography>    
  
 </div>
          </Grid>

          <Grid item xs={12} sm={6} className="D6_ADS_Grid1_Online">
            <div className="D6_ADS_G1_Div_Online" >
              <img src={ServicesImage1} alt="Image" className="D6_ADS_Img_Online" />
            </div>
          </Grid>
          {/* <Grid item xs={12} sm={6} className="D6_ADS_Grid1_Online">
            <div className="D6_ADS_G1_Div_Online" >
              <img src={SkinImage} alt="Image" className="D6_ADS_Img_Online" />
            </div>
          </Grid> */}
          {/* <Grid item sm={6} xs={12} className="D6_ADS_Grid1_Online">
            <div className="D6_ADS_G1_Div_Online">
              <div className='D6_SL_WS_Grid2_HDiv_Online'>
                <div className='D6_SL_WS_HIcon_Online'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1_Online'>ADHD SPECIALITY CLINIC</Typography>
            </div>
              <Typography
                component="p"
                className="D6_ADS_G1_DivP_Online_B"
              >
ATAM AVLOKAN IS EXCITED TO INTRODUCE A SPECIALITY CLINIC FOR ATTENTION DEFICIT HYPERACTIVITY DISORDER </Typography>
<Typography
                component="p"
                className="D6_ADS_G1_DivP_Online"
              >
First Step Wellness  is a pioneer in substance abuse treatments. ADHD which was found in patients with addiction problem. It was realized that early diagnosis & management of ADHD can prevent many persons developing substance abuse issues. </Typography>

<Typography
                component="p"
                className="D6_ADS_G1_DivP_Online"
              >
ADHD is a common disorder with an onset in childhood with negative consequences in education, oppositional behaviors as high risk behaviors. Unfortunately awareness among general public as well as medical professionals is not widespread.  </Typography>
           
  
 </div>
          </Grid> */}
        </Grid>
      </Container>
    </div>
  );
};

export default Online_cat;
