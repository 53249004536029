import React,{useState} from 'react';
import styled from 'styled-components';
import {NavLink} from "react-router-dom";
import "../../../MainCSS.css"
import { D6_sl_MenuList } from './D6_sl_MenuList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './D6_SL_Header_CSS.css'
import'./D6_SL_Menu.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  text-decoration: none;
  color: white;
  margin-right: 80px;
  font-size: 18px;
  li {
    padding: 18px 10px;
    padding: 30px 20px;
  }
  li a {
    text-decoration: none;
    color: white;
    
  }
  li a:hover {
    color: var(--ycolor);
  }
  @media (max-width: 990px) {
    padding-top: 0.75rem;
    
      li {
    padding: 27px 10px;
    }
  }
  @media (max-width: 768px) {
    margin-right: 0px;
    text-align: center;
    flex-flow: column;
    padding-right: 0px;
    background-color: #36444e;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    top: 90px;
    right: 0;
    z-index: 1000;
    height: 250px;
    width: 100%;
    padding-top: 26px;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
      padding: 18px 10px;
    }
  }
`;
const D6_SL_Menu = ({ open }) => {
  const config=[
    {
      "title": "Option 1",
      "submenu": null
    },
    {
      "title": "Option 2",
      "submenu": [
        {
          "title": "Option 2.1",
          "submenu": [
            {
              "title": "Option 2.1.1",
              "submenu": null
            },
            {
              "title": "Option 2.1.2",
              "submenu": null
            }
          ]
        },
        {
          "title": "Option 2.2",
          "submenu": [
            {
              "title": "Option 2.2.1",
              "submenu": null
            },
            {
              "title": "Option 2.2.2",
              "submenu": null
            }
          ]
        }
      ]
    }
  ]

  const [anchorEl, setAnchorEl] =useState(null);
  const [anchorW, setAnchorW] =useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickW= event => {
    setAnchorW(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseW= () => {
    setAnchorW(null);
  };
  const [anchorEl1, setAnchorEl1] =useState(null);
  const handleClick1 = event => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const [anchorEl2, setAnchorEl2] =useState(null);
  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  // const openp = Boolean(anchorEl);
  // console.log(anchorEl)
const handleCloseA=()=>{
  setAnchorEl(null)
  setAnchorEl1(null)
  setAnchorEl2(null)
}


  return (
    <Ul open={open} className="D6_SL_Navbar_List">
                        <li>
                            <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="/">
                              {D6_sl_MenuList[0]}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="/AboutUs">
                            {D6_sl_MenuList[1]}                           
                             </NavLink>
                        </li>
                     
                       
                        <li>
                        {/* <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="#"> */}

                          <div   className='select' >
                          <NavLink  className='menuService' activeClassName='active1' exact to='/Services' 
                           >Services</NavLink>
                          <ArrowDropDownIcon style={{fontSize:'25px',cursor:'pointer'}}  onClick={(e)=>handleClick(e)}/>
                       </div>
                        <Menu
        
        id="simple-menu"
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted
        className='services_menu'
        // className='D6_menu1'
        open={Boolean(anchorEl)}
        onClose={handleClose}
            >

             
        <MenuItem onClick={()=>window.location.pathname="/Services/Residential-care"} >RESIDENTIAL CARE</MenuItem>
        <MenuItem onClick={()=>window.location.pathname="/Services/Outpatient-care"} >OUTPATIENT CARE</MenuItem>
        <MenuItem onClick={()=>window.location.pathname="/Services/Onlinepatient-care"} >ONLINE PATIENT CARE</MenuItem>
        {/* <MenuItem onClick={()=>window.location.pathname="/Services/Psychological-care"} >PSYCHOLOGICAL/ PSYCHOMETRIC CARE</MenuItem> */}
        <MenuItem onClick={()=>window.location.pathname="/Services/Family-support"} >FAMILY SUPPORT SERVICES</MenuItem>
        <MenuItem onClick={()=>window.location.pathname="/Services/After-care"} >AFTER CARE SERVICES </MenuItem>
        {/* <MenuItem onClick={()=>window.location.pathname="/Services/Community"} >COMMUNITY SERVICES</MenuItem> */}
        <MenuItem onClick={()=>window.location.pathname="/Services/Training/Internship"} >TRAINING/ INTERNSHIP </MenuItem>
    

      </Menu>
      {/* </NavLink> */}

       </li>
       <li>
                        {/* <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="#"> */}

                         
                        <div   className='select' >
                          <NavLink  className='menuService' activeClassName='active1' exact to='/What-we-treat' 
                           >{D6_sl_MenuList[3]}</NavLink>
                          <ArrowDropDownIcon style={{fontSize:'25px',cursor:'pointer'}}  onClick={(e)=>handleClickW(e)}/>
                       </div>
                        <Menu
        
        id="simple-menu"
        anchorEl={anchorW}
        disableScrollLock={true}
        keepMounted
        className='services_menu2'
        open={Boolean(anchorW)}
        onClose={handleCloseW}
            >

             
        <MenuItem onClick={()=>window.location.pathname="/What-we-treat/Alcohol-addiction-treatment"}>ALCOHOL ADDICTION TREATMENT </MenuItem>
        <MenuItem onClick={()=>window.location.pathname="/What-we-treat/Drug-addiction-treatment"}>DRUG ADDICTION TREATMENT</MenuItem>
        <MenuItem onClick={()=>window.location.pathname="/What-we-treat/Dual-diagnosis"}>DUAL DIAGNOSIS</MenuItem>
        <MenuItem onClick={()=>window.location.pathname="/What-we-treat/Psychiatric-conditions"}>PSYCHIATRIC CONDITIONS</MenuItem>

        
       
      
        {/* <MenuItem>
        <h1   style={{fontSize:'18px',fontWeight:'300'}} onClick={(e)=>handleClick1(e)}>Sub Menu1</h1>

        <Menu
        id="simple-menu"
        anchorEl={anchorEl1}
        keepMounted
        open={Boolean(anchorEl1)}
        onClose={handleClose1}
      >
        <MenuItem>My account</MenuItem>
        <MenuItem  >Logout</MenuItem> 
     

      </Menu>
      </MenuItem> */}

      </Menu>
      {/* </NavLink> */}

       </li>
                        {/* <li>
                            <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="/Gallery">
                            {D6_sl_MenuList[4]}                         
                             </NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="/Blogs">
                            {D6_sl_MenuList[5]}                         
                             </NavLink>
                        </li> */}
       <li>
                            <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="/ContactUs">
                            {D6_sl_MenuList[2]}                         
                             </NavLink>
                        </li>
        {/* <li>
       <select  id = "dropdown"  value={config} onChange={(e)=>console.log(e.target.value)}>
   
   {config.map((category, idx) =>
                        {
                          return <>
                          
                           <option key={idx} value={category.title} >{category.title}</option>
                           {category.submenu?category.submenu.map((category, idx) =>
                           
                           {
                             return <option key={idx} value={category.title} >{category.title}</option>

                           }
                           ):null}
    
                          </>
                             
                          
                        }
 
              )  }
 </select><br />
 </li>  */}
    </Ul>
  )
}


export default D6_SL_Menu