import React from 'react';
import './Training_BgImage_CSS.css';

function Training_BgImage() {
    return(
        <div className="Dec6_Industries_BgImage_Tr">

        </div>
    );
}

export default Training_BgImage;