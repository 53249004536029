import React from 'react'
import AboutDASAC from './AboutDASACSection/D6_DASAC_ADS'
// import AboutTool from './About_Tools/D6_DASAC_AT'
import AboutTeam from './About_TeamSection/D6_DASAC_TS'
import './D6_DASAC.css'
const D6_DASAC_AboutUs = () => {
    return (
        <>
<div className='DASAC_BG'>

           <AboutDASAC/> 
           <AboutTeam/>
           </div>
           {/* <AboutTool/> */}
        </>
    )
}

export default D6_DASAC_AboutUs
