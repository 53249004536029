import React from 'react';
import './Psych_below_img.css';
import "../../../MainCSS.css";


function Psych_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_IPy">
            <h3 className="Dec6_Services_WCU_Cont_H_IPy">
            PSYCHIATRIC CONDITIONS      </h3>
            <p className="Dec6_Services_WCU_Cont_P_IPy">
            First step Wellness Center has a team of Physiatrists available for physiatrist assessment and intervention. Our psychiatric  services are available for inpatients as we as online patients.
            </p>                     
             <i  className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_IPy"></i>
        </div>
    );
}

export default Psych_below_img;