import React from 'react';
import './community_below_img.css';
import "../../../../MainCSS.css";


function Community_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_IComm">
            <h3 className="Dec6_Services_WCU_Cont_H_IComm">
            COMMUNITY SUPPORT PROGRAM            </h3>
            <p className="Dec6_Services_WCU_Cont_P_IComm">
            The Program uses six principles as a unifying concept : Inclusion, Participation, Sustainability, Empowerment, Self-advocacy and Barrier free environment.             </p>
                                    <i className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_IComm"></i>
        </div>
    );
}

export default Community_below_img;