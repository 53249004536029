import React from 'react';
import './Dual_below_img.css';
import "../../../MainCSS.css";


function Dual_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_Du">
            <h3 className="Dec6_Services_WCU_Cont_H_Du">
            DUAL ADDICTION TREATMENT            </h3>
            <p className="Dec6_Services_WCU_Cont_P_Du">
            Treatment for addiction and mental health
            </p>                     
             <i  className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_Du"></i>
        </div>
    );
}

export default Dual_below_img;