import React from 'react';
import './Services_cat_CSS.css';
import '../../MainCSS.css';
import {Paper,Container,Typography,Button,Box,Grid} from "@mui/material";

const Dec6_Industries_B2B_Cont_Typ3_Style1 = {
    fontSize : '0.95rem',
    paddingTop : '20px',
    paddingBottom : '5px'
}
const Dec6_Industries_B2B_Cont_Typ3_Style = {
    fontSize : '0.95rem',
    paddingBottom : '5px'
}
const Dec6_Industries_B2B_Cont_Typ2_Style = {
    fontSize : '1rem',
    paddingTop : '5px',
    fontWeight: 'lighter'
}
const Dec6_Industries_B2B_Cont_Button_Style = {
    fontSize : '1rem',
    marginTop : '20px',
    color : 'var(--whitecolor)',
    backgroundColor : 'var(--redcolor)',
    paddingBottom : '5px'
}

const Grid_Style = {
    justifyItems: 'center',
    alignItems: 'center',
    direction: 'row'
}

function Dec6_Industries_B2ESec() {
    return(
        <div>
    <Container>
        <Grid container justify="center" alignItems="center" fixed className="Dec6_Industries_B2B_Grid"   >
               
   {/*     <div className="Dec6_Industries_B2B_Cont_MainBlock">    */}
        <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_Industries_B2B_Grid1"  >
            <Container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
                <Paper elevation={14} className="Dec6_Industries_B2B_Cont_Paper">
                    <Box className="Dec6_Industries_B2B_Cont_Paper_Box">
                        <Typography variant="h5" className="Dec6_Industries_B2B_Cont_Typ1">
                        RESIDENTIAL CARE   
                        </Typography>          
                        <ul className="ulComm1">
            <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
Detox                </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
Comprehensive medical assessment                    </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
                Psychiatric assessment  
                </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
                Daily Yoga and meditation  
                </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
                Input sessions  
                </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
                Input sessions  
                </li>
                </ul>
                        {/* <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                        abcdefg  
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                        abcdefg
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                        abcdefg 
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                        abcdefg  
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                        abcdefg 
                        </Typography>
                        */}
                        <Button  href='/Services/Residential-care' className="Dec6_Industries_B2B_Cont_Button" variant="contained">Read More</Button>
                    </Box>
                </Paper>
            </Container>
        </Grid>
        <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_Industries_B2B_Grid2">
            <Container className="Dec6_Industries_B2B_Cont">
                <Paper elevation={14} className="Dec6_Industries_B2B_Cont_Paper">
                    <Box className="Dec6_Industries_B2B_Cont_Paper_Box">
                    <Typography variant="h5" className="Dec6_Industries_B2B_Cont_Typ1">
                    OUTPATIENT CARE 

                        </Typography>
                     
                        {/* <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style1} className="Dec6_Industries_B2B_Cont_Typ3">
                            vvvvccccc
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            vvvvccccc
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            vvvvccccc
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            vvvvccccc
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            vvvvccccc
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            vvvvccccc
                        </Typography> */}
                             <ul className="ulComm2">
            <li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
First Step Wellness intensive outpatient treatment program is ideal for those who do not require medically supervised detoxification              </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
First Step Wellness outpatient program follows the same principles as the highly rated RESIDENTIAL PROGRAM and provides individualized focus within the program structure.                   </li>
                
               
               
                </ul>
                        <Button className="Dec6_Industries_B2B_Cont_Button" href="/Services/Outpatient-care" style={Dec6_Industries_B2B_Cont_Button_Style}  variant="contained">Read More</Button>
                    </Box>
                </Paper>
            </Container>
            </Grid>

            <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_Industries_B2B_Grid3" >
            <Container className="Dec6_Industries_B2B_Cont">
                <Paper elevation={14} className="Dec6_Industries_B2B_Cont_Paper">
                    <Box className="Dec6_Industries_B2B_Cont_Paper_Box">
                        <Typography variant="h5" className="Dec6_Industries_B2B_Cont_Typ1">
                        ONLINE PATENT CARE  

                        </Typography>
                       
                        <ul className="ulComm1">
            <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
Schizophrenia                </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
Anxiety disorders                    </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
                Bipolar affective disorder  
                </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
                Depression  
                </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
                Sleep-related issues  
                </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
                Obsessive Compulsive Disorder (OCD)  
                </li>
                </ul>
                        <Button className="Dec6_Industries_B2B_Cont_Button" href='/Services/Onlinepatient-care'   variant="contained">Read More</Button>
                    </Box>
                </Paper>
            </Container>
            </Grid>
    {/*    </div>    */}
        </Grid>
        
        </Container>
        <Container>
        <Grid container justify="center" alignItems="center" fixed className="Dec6_Industries_B2B_GridS"   >
               
               {/*     <div className="Dec6_Industries_B2B_Cont_MainBlock">    */}
                    {/* <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_Industries_B2B_Grid1"  >
                        <Container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
                            <Paper elevation={14} className="Dec6_Industries_B2B_Cont_Paper">
                                <Box className="Dec6_Industries_B2B_Cont_Paper_Box">
                                    <Typography variant="h5" className="Dec6_Industries_B2B_Cont_Typ1">
                                    PSYCHOLOGICAL/ PSYCHOMETRIC CARE 
  
                                    </Typography>
                                   
                                    <ul className="ulComm1">
            <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
Stress management                </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
Grief counseling                   </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
                Anger management 
                </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
                Lifestyle disorders  
                </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B1"
              >
               Assertiveness  
                </li>
              
                </ul>
                                    <Button  href='/Services/Psychological-care'  className="Dec6_Industries_B2B_Cont_Button" variant="contained">Read More</Button>
                                </Box>
                            </Paper>
                        </Container>
                    </Grid> */}
                    <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_Industries_B2B_Grid2">
                        <Container className="Dec6_Industries_B2B_Cont">
                            <Paper elevation={14} className="Dec6_Industries_B2B_Cont_Paper">
                                <Box className="Dec6_Industries_B2B_Cont_Paper_Box">
                                <Typography variant="h5" className="Dec6_Industries_B2B_Cont_Typ1">
                                FAMILY SUPPORT SERVICES 

                                    </Typography>
                                   
                                    <ul className="ulComm2">
            <li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
Educating the family about addiction </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
Introduction to Al-anon family support groups</li>
                
<li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
Briefing the family about what to expect from the treatment program and how to cooperate for maximum benefit </li>
                
               
                </ul>
                                    <Button className="Dec6_Industries_B2B_Cont_Button" href='/Services/Family-support' style={Dec6_Industries_B2B_Cont_Button_Style}  variant="contained">Read More</Button>
                                </Box>
                            </Paper>
                        </Container>
                        </Grid>
            
                        <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_Industries_B2B_Grid3" >
                        <Container className="Dec6_Industries_B2B_Cont">
                            <Paper elevation={14} className="Dec6_Industries_B2B_Cont_Paper">
                                <Box className="Dec6_Industries_B2B_Cont_Paper_Box">
                                    <Typography variant="h5" className="Dec6_Industries_B2B_Cont_Typ1">
                                    AFTER CARE SERVICES 
                                    </Typography>
                                    <ul className="ulComm2">
            <li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
Prior to discharge, each client goes through a personalized and comprehensive Relapse Prevention Plan </li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
We recognize that post treatment; early recovery is a vulnerable time</li>
                
<li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
As a part of the follow-up program, the client and family is expected to visit the treatment center </li>
                
               
                </ul>
                                    <Button  className="Dec6_Industries_B2B_Cont_Button" href="/Services/After-care" style={Dec6_Industries_B2B_Cont_Button_Style}  variant="contained">Read More</Button>
                                </Box>
                            </Paper>
                        </Container>
                        </Grid>
                        <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_Industries_B2B_Grid2">
                        <Container className="Dec6_Industries_B2B_Cont">
                            <Paper elevation={14} className="Dec6_Industries_B2B_Cont_Paper">
                                <Box className="Dec6_Industries_B2B_Cont_Paper_Box">
                                <Typography variant="h5" className="Dec6_Industries_B2B_Cont_Typ1">
                                TRAINING/ INTERNSHIP 
                                    </Typography>
                                    <ul className="ulComm2">
            <li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
First Step Wellness now offers a unique opportunity for students and mental health professionals.</li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
Get exposed to residential and therapeutic community settings  </li>
                
<li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
Expand your knowledge base and gain valuable experience in the upcoming field of addiction treatment </li>
                
               
                </ul>
                    
                                    <Button className="Dec6_Industries_B2B_Cont_Button" href="/Services/Training/Internship" style={Dec6_Industries_B2B_Cont_Button_Style}  variant="contained">Read More</Button>
                                </Box>
                            </Paper>
                        </Container>
                        </Grid>
                {/*    </div>    */}
                    </Grid>
        </Container>
        <Container>
        <Grid container justify="center" alignItems="center" fixed className="Dec6_Industries_B2B_GridS"   >
               
               {/*     <div className="Dec6_Industries_B2B_Cont_MainBlock">    */}
                    {/* <Grid item lg={6} sm={12} md={6} xs={12} className="Dec6_Industries_B2B_Grid1"  >
                        <Container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
                            <Paper elevation={14} className="Dec6_Industries_B2B_Cont_Paper">
                                <Box className="Dec6_Industries_B2B_Cont_Paper_Box">
                                    <Typography variant="h5" className="Dec6_Industries_B2B_Cont_Typ1">
                                    COMMUNITY SERVICES 
  
                                    </Typography>
                                    <ul className="ulComm2">
            <li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
Identified segments of society, especially the marginalized and underprivileged like jail inmates, street children and similar forums</li>
                <li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
Participation in the client’s relapse prevention plan </li>
                
<li
                component="p"
                className="D6_ADS_G1_DivPComm_B2"
              >
Public institutions like  National Police Academy, DELHI Police and other institutions</li>
                
               
                </ul>
                                  
                                    <Button href='/Services/Community' style={Dec6_Industries_B2B_Cont_Button_Style} className="Dec6_Industries_B2B_Cont_Button" variant="contained">Read More</Button>
                                </Box>
                            </Paper>
                        </Container>
                    </Grid> */}
                  
            
                        
                {/*    </div>    */}
                    </Grid>
        </Container>
        </div>
    );
}

export default Dec6_Industries_B2ESec;