import React from 'react';
import './community_care_BgImage_CSS.css';

function  Community_care_BgImage() {
    return(
        <div className="Dec6_Industries_BgImage2_Comm">

        </div>
    );
}

export default Community_care_BgImage;