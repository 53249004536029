import React, { useState } from 'react';
import styled from 'styled-components';
import RightNav from './D6_SL_Menu';
import '../../../MainCSS.css'
import { D6_sl_MenuList } from './D6_sl_MenuList';
import D6_SL_Menu from './D6_SL_Menu';
import TemporaryDrawer from './dashboard'
import './D6_SL_Hamburger.css'
const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 24px;
  right: 20px;
  right: 20px;
  z-index: 20;
  
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    margin-top: 100px;
  }
  div {
    width: 2rem;
    height: 2px;  
    background-color: ${({ open }) => open ? 'var(--ycolor)' : 'white'};
    border-radius: 10px;  
    transform-origin: 1px;
  
  }
`;


const D6_SL_Hamburger = (props) => {
  const [open, setOpen] = useState(false)
  const Toggle=()=>{
    setOpen(!open)
  }
  return (
    <>
      <StyledBurger open={open}   >
        <div className='abcd' onClick={()=>setOpen(true)}>
        <div className="HBLine1"/>
        <div  className="HBLine2"/>
        {/* {!open? */}
        <div  className="HBLine1"/>
        </div>
        {/* <D6_SL_Menu open={open}/> */}
        <TemporaryDrawer Toggle={Toggle} open={open}  />
      </StyledBurger>
      <D6_SL_Menu />
      
    

    
    </>
    
  )
}
export default D6_SL_Hamburger