import React from 'react';
import './Outpatient_care_below_img.css';
import "../../../../MainCSS.css";


function Outpatient_care_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_I_Out">
            <h3 className="Dec6_Services_WCU_Cont_H_I_Out">
            OUTPATIENT CARE
            </h3>
            <p className="Dec6_Services_WCU_Cont_P_I_Out">
            First Step Wellness has a highly skilled TEAM of addiction therapist, clinical psychologists, and psychiatrists to address a variety of substance abuse, psychological and psychiatric issues.             </p>
                        <i className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_I_Out"></i>
        </div>
    );
}

export default Outpatient_care_below_img;