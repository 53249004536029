import React from 'react';
import './Family_support_BgImage_CSS.css';

function Family_support_BgImage() {
    return(
        <div className="Dec6_Industries_BgImage_Family">

        </div>
    );
}

export default Family_support_BgImage;