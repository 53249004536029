import React from 'react'
import Dual_below_img from './Dual_below_img'
import Dual_BgImage from './Dual_BgImage'
import Dual_cat from './Dual_cat'
import './Dual_addic.css'
 export default function Dual_addict(){
     return(
<>
<div className='Dual_BG'>

<Dual_BgImage/>
<Dual_below_img/>
</div>
<Dual_cat/>
</>
     )
 }