import React from 'react';
import './After_care_below_img.css';
import "../../../../MainCSS.css";


function After_care_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_I_After">
            <h3 className="Dec6_Services_WCU_Cont_H_I_After">
            AFTER CARE &amp; SOBER LIVING PROGRAM             </h3>
            <p className="Dec6_Services_WCU_Cont_P_I_After">
            Left untreated, addiction is a devastating disease that is chronic, relapse-prone, progressive, and sometimes fatal.             </p>
                        <i className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_I_After"></i>
        </div>
    );
}

export default After_care_below_img;