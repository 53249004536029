import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './dashboard.css'
export default function TemporaryDrawer(props) {
  // const [state, setState] = React.useState({
  //   top: false,
  //   left: props.open,
  //   bottom: false,
  //   right: false,
  // });
  const [open,setOpen]=React.useState(props.open)


  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };
  const Open=()=>{
    setOpen(!open)
  }
const Toggle=()=>{
  props.Toggle()
}
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={Open}
      onKeyDown={Open}
    >
      <List>
        <ListItem onClick={()=>{Toggle()}}>
        <ListItemIcon>
      <ArrowForwardIcon  />
      </ListItemIcon>
      </ListItem>

        {['Home', 'About us'].map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='Home'?'/':'AboutUs'}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}
    
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography onClick={()=>window.location.pathname='/services'} >Services</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography onClick={()=>window.location.pathname="/Services/Residential-care"} className='typo'>
          RESIDENTIAL CARE
          </Typography>
          <Typography onClick={()=>window.location.pathname="/Services/Outpatient-care"} className='typo'>
            OUTPATIENT CARE 
          </Typography>
          <Typography onClick={()=>window.location.pathname="/Services/Onlinepatient-care"}  className='typo'>
            ONLINE PATIENT CARE 
          </Typography>
          {/* <Typography nClick={()=>window.location.pathname="/Services/Psychological-care"}  className='typo'>
          PSYCHOLOGICAL/ PSYCHOMETRIC CARE 
          </Typography> */}
          <Typography onClick={()=>window.location.pathname="/Services/Family-support"} className='typo'>
          FAMILY SUPPORT SERVICES 
          </Typography>
          <Typography onClick={()=>window.location.pathname="/Services/After-care"} className='typo'>
          AFTER CARE SERVICES 
          </Typography>
          {/* <Typography onClick={()=>window.location.pathname="/Services/Community"} className='typo'>
          COMMUNITY SERVICES 
          </Typography> */}
          <Typography onClick={()=>window.location.pathname="/Services/Training/Internship"} className='typo'>
          TRAINING/ INTERNSHIP  
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography onClick={()=>window.location.pathname='/What-we-treat'}>What do we treat</Typography>
        </AccordionSummary>
        <AccordionDetails>
          
        <Typography onClick={()=>window.location.pathname="/What-we-treat/Alcohol-addiction-treatment"} className='typo'>
        ALCOHOL ADDICTION TREATMENT
          </Typography>
          <Typography onClick={()=>window.location.pathname="/What-we-treat/Drug-addiction-treatment"} className='typo'>
          DRUG ADDICTION TREATMENT 
          </Typography>
          <Typography onClick={()=>window.location.pathname="/What-we-treat/Dual-diagnosis"} className='typo'>
          DUAL DIAGNOSIS 
          </Typography>
          <Typography  onClick={()=>window.location.pathname="/What-we-treat/Psychiatric-conditions"} className='typo'>
          PSYCHIATRIC CONDITIONS 
          </Typography>
        </AccordionDetails>
      </Accordion>
      {['Contact us'].map((text, index) => (
           <Accordion  onClick={()=>window.location.pathname=`${text==='Contact us'?'ContactUs':text}`}  key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}
      </List>

    </Box>
  );

  return (
    // <div>
    <>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <Drawer
            anchor={'right'}
            open={props.open}
            onClose={Open}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      </>
    // </div>
  );
}