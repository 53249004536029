import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@mui/material";
import SkinImage from "../../../assets/Images/AboutUs.jpg";
import HIcon from '../../../assets/Images/logo.png';
import "../../../MainCSS.css";
import "./D6_DASAC_ADS.css"
const D6_DASAC_ADS = () => {
  return (
    <div >
      <Grid item xs={12} className="D6_ADS_Grid0">
        {/* <div className="D6_DS_BG_Div">
          <img src={ServicesImage} className='img' alt="aboutus" />
        </div> */}
      </Grid>
      <div className="D6_Below_img">
      <Container className="D6_ADS_Cont_DASAC">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_Grid"
        >
          <Grid item sm={8} xs={12} className="D6_ADS_Grid1">
            <div className="D6_ADS_G1_Div">
              {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                About RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDiv'>
                <div className='D6_SL_WS_HIcon'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1'>First Step Wellness Retreat</Typography>
            </div>
              <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
                {/* The Aatm Avlokan is a luxury Rehab &amp; Wellness Facility for Alcohol, Drug Addiction and those afflicted with Mental and Psychological Disorders &amp; Conditions based in Noida, Delhi (NCR) India. 
                We have a holistic and eclectic approach towards the treatment for Addiction. Our treatment is the combination of medicine, therapies and activities. */}
              The First Step Rehab is a physiological Rehab & Wellness Facility for Addiction and those afflicted with Mental Disorders based in 
              Noida, Delhi (NCR) India. We have a holistic and eclectic approach towards the treatment for Addiction and Psychosocial disorder. 
              Our treatment is the combination of medicine, practicing Mindfulness therapies. 
              We understand the serious condition of the suffering addicts and their family and offer treatment that changes lives. 

              </Typography>
              <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
                We are dedicated to ensuring that all patients receive the care that they need 
                in an encouraging environment where they can collaborate with treatment professionals in an atmosphere of dignity and respect.
                
              </Typography>
              <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
                Our Reliable & Effective Drug and Alcohol Addiction Recovery program is headed and supported by experienced psychiatrists, counselors & 
                dedicated Spiritual Therapists and driven by motivated staff with years of experience in treating addiction.
              </Typography>
              <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
                Our integrated Addiction Recovery program is designed under the auspices of the 12 Step Program which is based on the pillars of 
                honesty, open-mindedness, and willingness, 
                With a combination yoga, meditation, CBT and REBT approved by the World Health Organization for Addiction Treatment.
              </Typography>
              <Typography
                component="p"
                className="D6_ADS_G1_DivP"
              >
                {/* Aatm  Avlokan's holistic treatment aims at  their physical, psychological, emotional, social and spiritual ailments towards Total Recovery. Means we are dedicated to healing the whole self. Restoring a person’s neurochemistry is an essential part of our successful treatment. 
We provide affordable luxury and recovery in a setting where lasting health and peace of mind are the goals. Wellness is so much more than just physical well-being. It`s a balance in mind, body, and sprit. */}
First step wellness center’s holistic treatment aims at  their physical, psychological, emotional, social and spiritual ailments towards 
total Recovery. Means we are dedicated to healing the whole self. Restoring a person’s neurochemistry is an essential part of our successful treatment. We provide affordable luxury and recovery in a setting where lasting health and peace of mind are the goals. 
Wellness is so much more than just physical well-being. It`s a balance in mind, body, and sprit.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={4} className="D6_ADS_Grid1">
            <div className="D6_ADS_G1_Div" >
              <img src={SkinImage} alt="Image" className="D6_ADS_Img" />
            </div>
          </Grid>
        </Grid>
      </Container>
      </div>
    </div>
  );
};

export default D6_DASAC_ADS;
