import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@mui/material";
import SkinImage from "../../../assets/Images/psy.jpg";
import HIcon from '../../../assets/Images/logo.png';
import "../../../MainCSS.css";
import "./Psych_cat.css"
const Psych_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_ContPy">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_GridPy"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1Py">
            <div className="D6_ADS_G1_DivPy">
              {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                AbFam RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDivPy'>
                <div className='D6_SL_WS_HIconPy'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1Py'>PSYCHIATRIC CONDITIONS </Typography>
            </div>
   
 <Typography           component="p"
 className="D6_ADS_G1_DivPPy_B">
We treat many different   mental health issues, which include 
 </Typography>
 <ul className="ul_dual">
  <li className="li_dual">Schizophrenia</li>
  <li className="li_dual">Depression</li>
  <li className="li_dual">Bipolar disorder</li>
  <li className="li_dual">Dementia </li>
  <li className="li_dual">Eating disorders, such as anorexia and bulimia</li>
  <li className="li_dual">Hallucinations</li>
  <li className="li_dual">Personality Disorder</li>
  <li className="li_dual">Obsessive Compulsive Behavior</li>
  <li className="li_dual">Post-traumatic stress disorder (PTSD)</li>
  <li className="li_dual">OCD</li>
  <li className="li_dual">Insomnia and sleeping problems</li>
  <li className="li_dual">Addiction, including to gambling, drugs, alcohol, and certain behaviors</li>
  </ul>
 <Typography           component="p"
 className="D6_ADS_G1_DivPPy_B">
At our First Step wellness Center we treat many different mental health issues that have real, tangible impacts on your patients’ daily lives.
</Typography>
<Typography           component="p"
 className="D6_ADS_G1_DivPPy_B">
<span style ={{color: 'green'}} >If you or someone you love is struggling with an anxiety disorder or Psychiatric, don’t hesitate to reach out for help. Treatment is more accessible than you think.</span>
</Typography>
<Typography           component="p"
 className="D6_ADS_G1_DivPPy_B">
<span style ={{color: 'green'}} >Contact us today for more information </span>
</Typography>
  

              
  
 </div>
          </Grid>

          <Grid item xs={12} sm={6} className="D6_ADS_Grid1Py">
            <div className="D6_ADS_G1_DivPy" >
              <img src={SkinImage} alt="Image" className="D6_ADS_ImgPy" />
            </div>
          </Grid>

      
      
        </Grid>

      </Container>
    </div>
  );
};

export default Psych_cat;
