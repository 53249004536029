import React from 'react';
import './What_treat_BgImage_CSS.css';

function What_treat_BgImage() {
    return(
        <div className="Dec6_Industries_BgImage_Block_W">

        </div>
    );
}

export default What_treat_BgImage;