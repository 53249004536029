import React from 'react';
import './Drug_below_img.css';
import "../../../MainCSS.css";


function Drug_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_Dr">
            <h3 className="Dec6_Services_WCU_Cont_H_Dr">
            DRUG ADDICTION TREATMENT             </h3>
            <p className="Dec6_Services_WCU_Cont_P_Dr">
            Healing and Hope for those affected by addiction of drug
             </p>                     
              <i  className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_Dr"></i>
        </div>
    );
}

export default Drug_below_img;