import React from 'react'
import './D6_SL_AF_CSS.css';
import {Grid,Container,TextField} from '@mui/material';
import {NavLink} from "react-router-dom";
import Form from './D6_SL_AF_Form'
import { Dialog, DialogContent, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import { makeStyles } from '@mui/styles';

import './D6_SL_AF_CSS.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const D6_SL_AF = () => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    return (
   <div className="D6_AboveFooter_Container">   

 <Container fixed className="D6_AF_Grid_Cont">
 <Grid container justify="center" className="D6_AF_Grid">

        <Grid item md={4} xs={12} className="D6_AF_Grid3">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-3">
                <h3 className="D6_AboveFooter_H3">Our Services</h3>
                <ul className="D6_AboveFooter_List3">
                    <li>
                        <NavLink className="" exact to="/Services/Residential-care">
                            Residential Care
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="" exact to="/What-we-treat/Psychiatric-conditions">
                        Psychiatric Treatment
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="" exact to="/Services/Onlinepatient-care">
                            Online Patient Care
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="" exact to="/Services/Family-support">
                            Family support services
                        </NavLink>
                    </li>
                </ul>
             
            </div>
        </Grid>

        <Grid item md={4} xs={12} className="D6_AF_Grid5">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">  
                <h3 className="D6_AboveFooter_H1">Reach us</h3>
                <div className="D6_AboveFooter_Item5D1">
                    {/* <h3 className="D6_AboveFooter_HC">Address</h3> */}
                    <p className="D6_AboveFooter_HC1 D6_AboveFooter_HC11">
                    Delhi NCR 
                    </p>
                    {/* <p className="D6_AboveFooter_HC1">
                    Delhi &amp; Noida
                    </p> */}
                    {/* <p className="D6_AboveFooter_HC1">
                    New Delhi, 110011
                    </p> */}
                    <p className="D6_AboveFooter_HC1">
                    India
                    </p>
                    <div className="D6_AboveFooter_Icon1">
                        <i className="fas fa-phone-alt "></i>
                        <a href="tel:+91 (0) 9999361401"target="_blank" className="D6_AboveFooter_Icon_H">
                        +91 (0) 9999361401
                        </a>  
                    </div>
                    <div className="D6_AboveFooter_Icon1">
                        <i className="fas fa-phone-alt "></i>
                        <a href="tel:+91 (0) 9967270103"target="_blank" className="D6_AboveFooter_Icon_H">
                        +91 (0) 9967270103
                        </a>  
                    </div>
                    <div className="D6_AboveFooter_Icon1">
                        <i className="fa fa-envelope"></i>
                        <a href="mailto:info@afirststepwellness.org" target="_blank" className="D6_AboveFooter_Icon_E">
                        info@firststepwellness.org
                        </a>  
                    </div>

                    <div className="Dec6_AboveFooter_SymbolsList1">
                        <a href="mailto:info@firststepwellness.org" target="_blank" className="fab fa-facebook-f fa-5x circle-icon circle-icon-f aicon centericons"></a>
                        <a href="https://wa.me/+919999361401" target="_blank" className="fab fa-instagram fa-5x circle-icon circle-icon-tw aicon"></a>
                        <a href="https://halodigital.app/69210177176" target="_blank" className="fas fa-id-card circle-icon  circle-icon aicon ">
                      
                        </a>
                        <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        className="Dialog_CButton"
        disableBackdropClick
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="icon-close-btn">
            <CloseIcon />
          </IconButton>
          
          {/* <img style={{height:150,width:300}} src={Qr} />  */}
          <a href="https://halodigital.app/69210177176" target="_blank" className='openCard' >Open Card </a> 
                   {/* <AForm/> */}
        </DialogContent>
      </Dialog>
                        {/* <div  onClick={()=>setOpen(true)}  className="fas fa-qrcode  circle-icon circle-icon-f aicon"></div>    */}
                    </div> 
                </div>
            </div>
        </Grid>

        <Grid item md={4} xs={12} className="D6_AF_Grid4">
            <div className="D6_AF_Form_Div D6_AboveFooter_Item">
                <h3 className="D6_AF_Form_Div_H">
                    Any queries?
                </h3>  
                <Form/>
            </div>    
        </Grid>

    </Grid>   
</Container>  
</div>
    )
}

export default D6_SL_AF
