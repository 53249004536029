import React from 'react';
import './Inpatient_care_BgImage_CSS.css';

function Dec6_Industries_BgImage() {
    return(
        <div className="Dec6_Industries_BgImage1">

        </div>
    );
}

export default Dec6_Industries_BgImage;