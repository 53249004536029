import React from 'react';

import Carousel from './Home_SCarousel/D6_SL_SCarousel';
import WelcomeS from './Home_WelcomeSection/D6_SL_WS';
// import WCUS from './Home_WhyChooseUsS/D6_SL_WCU';
import Transformation from './Home_TransformationCarousel/D6_DASAC_HRSC';


const D6_SL_Home = () => {
    return (
        <>
            <Carousel/>    
            <WelcomeS/>
            {/* <WCUS/> */}
            {/* <Transformation /> */}
        </>
    )
}

export default D6_SL_Home
