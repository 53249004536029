import React from 'react';
import './Psy_care_below_img.css';
import "../../../../MainCSS.css";


function Psy_care_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_I_Psy">
            <h3 className="Dec6_Services_WCU_Cont_H_I_Psy">
            PSYCHOLOGICAL COUNSELLING
            </h3>
            <p className="Dec6_Services_WCU_Cont_P_I_Psy">
                        First Step Wellness has a highly skilled TEAM of addiction therapist, clinical psychologists, and psychiatrists to address a variety of substance abuse, psychological and psychiatric issues.          
                        </p>
            <p className="Dec6_Services_WCU_Cont_P_I_Psy">
            Psychosocial support aims to help traumatized people to return to an independent existence. Our experts from emergency humanitarian relief, social work, and psychotherapy offer a range of services to those affected.                       </p>
                        <i className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_I_Psy"></i>
                        
                        <p className="Dec6_Services_WCU_Cont_P_I_Psy">
                        Qualified clinical psychologists offer support for:                         </p>

        </div>
    );
}

export default Psy_care_below_img;