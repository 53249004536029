import React,{Component} from 'react'
import {TextField, Button} from '@mui/material'
import '../../../MainCSS.css'
import './D6_DASAC_SA_CSS.css'
import axios from 'axios';

const D6_DASAC_SA_DivS = {
    height: '200px',
    alignItems: 'center',
    textAlign : 'center'
}
const D6_DASAC_SA_Div1S = {
    alignItems: 'center',
    textAlign : 'center'
}
const D6_DASAC_SA_Div1_H = {
    fontSize: '28px',
    paddingBottom: '20px',
    paddingTop: '20px',
    color: 'var(--gcolor)'
}
const D6_DASAC_CSA_DateDiv = {
    marginLeft: '-190px'
}
const D6_DASAC_CSA_TimeDiv = {
    marginTop : '-48px',
    marginLeft: '220px'
}

class D6_DASAC_SA extends Component {

    constructor() {
        super();
        this.state={
            date : '',
            time: '',
            submitted: false
        }
        this.changeDate= this.changeDate.bind(this)
        this.changeTime= this.changeTime.bind(this)
    } 

    changeDate(event) {
        this.setState({
            date:event.target.value
        })
        console.log(this.state.date)
    } 
    changeTime(event) {
        this.setState({
            time:event.target.value
        })
        console.log(this.state.time)
    } 
    handleSubmit = (event) => {
        event.preventDefault()
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 10000);
        });

        const adddata = {
                name: 'ABC',
                email: 'nilesh1998sharma@gmail.com',
                phonenumber: '912356799776',
                message: 'ABC',
                date: this.state.date,
                time: this.state.time,
                clientid: 'd6_dasac'
            }
            console.log(adddata)

        axios.post('https://d6apicluster.herokuapp.com/appointments', adddata)
        .then(response => console.log(response.data))
           
          this.setState({
            date:'',
            time:''
        }) 
    }

    render() {
        const {submitted } = this.state;
    return (
        <div className="D6_DASAC_SA_Div" style={D6_DASAC_SA_DivS}>
            <div className="D6_DASAC_SA_Div1" style={D6_DASAC_SA_Div1S}>
                <h3 className="D6_DASAC_SA_Div1_H" style={D6_DASAC_SA_Div1_H}>Schedule Appointment</h3>
            </div>
            <div>
            <form className="D6_DASAC_Form" noValidate onSubmit={this.handleSubmit}>

                <div className="D6_DASAC_CSA_DateDiv" style={D6_DASAC_CSA_DateDiv}>
                    <TextField
                        id="date"
                        label="Select date"
                        type="date"
                        value={this.state.date}
                        onChange={(e)=>this.setState({
                            date:e.target.value
                        })}
                /*      className={classes.textField}   */
                        InputLabelProps={{
                        shrink: true,

                        }}
                    />
                </div>
                <div className="D6_DASAC_CSA_TimeDiv" style={D6_DASAC_CSA_TimeDiv}>
                    <TextField
                        id="time"
                        label="Select time"
                        type="time"
                        value={this.state.time}
                        onChange={this.changeTime}
                    /*    className={classes.textField}  */
                        InputLabelProps={{
                        shrink: true,
                        }}
                        inputProps={{
                        step: 300, // 5 min
                        }}
                    />
                </div>

                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Thanks for scheduling appointment!')
                        || (!submitted && 'Submit')
                    }
                </Button>
            </form>
            </div>
        </div>
    )
    }
}

export default D6_DASAC_SA
