import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@mui/material";
import SkinImage from "../../../../assets/Images/AM_Self.jpg";
 import HIcon from '../../../../assets/Images/logo.png';
import "../../../../MainCSS.css";
import "./community_cat.css"
const Community_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_ContComm">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_GridComm"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1Comm">
            <div className="D6_ADS_G1_DivComm">
              {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                AbFam RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDivComm'>
                <div className='D6_SL_WS_HIconComm'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1Comm'>COMMUNITY SERVICES </Typography>
            </div>
            <Typography
                component="p"
                className="D6_ADS_G1_DivPComm_B"
              >
First Step Wellness is strongly committed to serving the community. We plays an active role at various levels, conducting focused programs aimed at building awareness of addiction related issues. Such programs form the prevention aspect of our PERT model (Prevention, Education, Research, and Treatment). First Step Wellness also collaborates with agencies (such as Police Department, corporates and educational institutions) for such initiatives. </Typography>
       
  

              
  
 </div>
          </Grid>

          <Grid item xs={12} sm={6} className="D6_ADS_Grid1Comm">
            <div className="D6_ADS_G1_DivComm" >
              <img src={SkinImage} alt="Image" className="D6_ADS_ImgComm" />
            </div>
          </Grid>



          <Grid item xs={12} position={'center'} sm={12} className="D6_ADS_Grid1Comm">
            <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1Comm_list'>First Step Wellness CONDUCTS AWARENESS PROGRAMS AT:  </Typography>

          </Grid>
          <Grid item sm={12} xs={12} className="D6_ADS_Grid1Comm">
   
            <ul className="ulComm">
            <li
                component="p"
                className="D6_ADS_G1_DivPComm_B"
              >
                Educating the family about addiction  
                </li>
<li  component="p"
                className="D6_ADS_G1_DivPComm_B">
Identified segments of society, especially the marginalized and underprivileged like jail inmates, street children and similar forums </li>
<li  component="p"
                className="D6_ADS_G1_DivPComm_B">
Corporates(Employee,AssistancePrograms) 
First Step Wellness  has conducted sensitization programs at companies on addiction at the workplace. Some companies include ITC, Mahindra Satyam, Coca Cola, Usha Shriram, and many others  </li>
<li  component="p"
                className="D6_ADS_G1_DivPComm_B">
Public institutions like  National Police Academy, DELHI Police and other institutions </li>
<li  component="p"
                className="D6_ADS_G1_DivPComm_B">
Institutions such as schools, colleges and clubs </li>
<li  component="p"
                className="D6_ADS_G1_DivPComm_B">
Participation in the client’s relapse prevention plan </li>
<li  component="p"
                className="D6_ADS_G1_DivPComm_B">
Social Media (Facebook, INSTAGRAM LinkedIn and Twitter) </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Community_cat;
