import React from 'react';
import './Training_below_img.css';
import "../../../../MainCSS.css";


function Training_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_ITr">
            <h3 className="Dec6_Services_WCU_Cont_H_ITr">
            TRAINING SUPPORT SERVICE            </h3>
            <p className="Dec6_Services_WCU_Cont_P_ITr">
            The internship programme aims to offer an intensive training in field based research, community outreach and campaigning. The experience gained will help the interns to back them academically as well as empirically to advance their career in Policy Making.              
            </p>                      <i  className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_ITr"></i>
        </div>
    );
}

export default Training_below_img;