import React from 'react';
import './Online_care_BgImage_CSS.css';

function Online_care_BgImage() {
    return(
        <div className="Dec6_Industries_BgImage_Online">

        </div>
    );
}

export default Online_care_BgImage;