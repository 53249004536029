import "./D6_DASAC_HRSC_CSS.css";
import ImageSlider from "./D6_DASAC_ImageSlider";

import { IMAGES} from "./D6_DASAC_Data";

function D6_DASAC_HRSC() {
  return (
    <div className="HRSC">
      <div className="HRSC_container">
      <h3 className="D6_DASAC_RS_Div_H">
              OUR ACTIVITIES
        </h3>
        <ImageSlider images={IMAGES} slidesToShow={4} />  
        {/* <div className="RS_Div_BH">
          Before
          </div>
          <div className="RS_Div_AH">
            After 
          </div>      */}
      </div>
    </div>
  );
}

export default D6_DASAC_HRSC;