import React from 'react'
import Psy_care_below_img from './Psy_care_below_img'
import Psy_care_BgImage from './Psy_care_BgImage'
import Psy_cat from './Psy_cat'
import './Psy_care.css'
const Psy_care = () => {
    return (
        <>
                         <div className='Psy_BG'>

          <Psy_care_BgImage  />  
          <Psy_care_below_img />
          </div>

          <Psy_cat />
        </>
    )
}

export default Psy_care
