import React from 'react'
import {Grid,Container} from '@mui/material';
import { makeStyles } from "@mui/styles";
import './D6_DASAC_CMI_CSS.css';

  
const D6_DASAC_CMI = () => {
    return (
    <Container className="D6_ADS_Cont">
        <Grid 
            container
            direction="row" 
            justify="space-around" 
             fixed  className="D6_SL_CU_Map_Grid">
            <Grid  item sm={4} xs={12} className="D6_SL_CU_Map_Grid1">
                <div  className="D6_SL_CU_Map_Grid_Div">   
                    <h3 className="D6_SL_CU_Map_Grid_H">
                        Location 
                    </h3>
                    <h3 className="D6_SL_CU_Map_Grid_H1 D6_SL_CU_Map_Grid_Add">
                    Delhi-NCR
                    </h3>
                    <h3 className="D6_SL_CU_Map_Grid_H1 D6_SL_CU_Map_Grid_Add2">
                    New Delhi &amp; Noida
                    </h3>
                    <h3 className="D6_SL_CU_Map_Grid_H1 D6_SL_CU_Map_Grid_Add2">
                    India
                    </h3>
                </div> 

                <div  className="D6_SL_CU_Map_Grid_DivT D6_SL_CU_Map_Grid_Div">   
                    <h3 className="D6_SL_CU_Map_Grid_H">
                        Timings
                    </h3>
                    <h3 className="D6_SL_CU_Map_Grid_H1 D6_SL_CU_Map_Grid_Add">
                        All Days (Monday-Sunday)
                    </h3>
                    <h3 className="D6_SL_CU_Map_Grid_H1 D6_SL_CU_Map_Grid_Add2">
                        24 x 7
                    </h3>
                </div>  

                <div  className="D6_SL_CU_Map_Grid_Div">
                    <h3 className="D6_SL_CU_Map_Grid_H">
                        Telephone &amp; Email
                    </h3>
                    <div className="D6_SL_CU_Map_Grid3_Detail">
                        <i className="fas fa-phone-alt D6_SL_CU_Map_Grid3_Detail_Icon"></i>
                        <a className="D6_SL_CU_Map_Grid3_Detail_Link" href="tel:+91 9999361401">
                        +91 9999361401
                        </a>
                    </div>
                    <div className="D6_SL_CU_Map_Grid3_Detail">
                        <i className="fas fa-phone-alt D6_SL_CU_Map_Grid3_Detail_Icon"></i>
                        <a className="D6_SL_CU_Map_Grid3_Detail_Link" href="tel:+919967270103">
                        +91 9967270103
                        </a>
                    </div>
                    <div className="D6_SL_CU_Map_Grid3_Detail">
                    <i className="fa fa-envelope D6_SL_CU_Map_Grid3_Detail_Icon"/>
                        <a className="D6_SL_CU_Map_Grid3_Detail_Link" href="mailto:info@firststepwellness.org">
                        info@firststepwellness.org
                        </a>
                    </div>
                </div>

                <div  className="D6_SL_CU_Map_Grid_Div D6_SL_CU_Map_Grid_Div_FU">
                    <h3 className="D6_SL_CU_Map_Grid_H">
                        Follow Us
                    </h3>
                    <div className="D6_SL_CU_Map_Link">
                        <a href="https://wa.me/+919999361401" target="_blank" className="fab fa-facebook-f D6_SL_CU_Map_Icon"></a>
                        <a href="https://wa.me/+919999361401" target="_blank" className="fab fa-instagram D6_SL_CU_Map_Icon"></a>
                        <a href="https://wa.me/+919999361401" target="_blank" className="fab fa-whatsapp D6_SL_CU_Map_Icon"></a>
                        <a href="https://halodigital.app/info?card=76810133761" target="_blank" className="fas fa-id-card D6_SL_CU_Map_Icon"></a> 
                    </div>
                    <div className="D6_SL_CU_Map_Link">
                        
                    </div>
                </div>
            </Grid>

            <Grid item sm={8} xs={12} className="D6_SL_CU_Map_Grid2">    
                <div className="D6_SL_CU_Map">
                    <iframe 
                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112173.02278547168!2d77.13894517048817!3d28.52748506152975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1642235455375!5m2!1sen!2sin"
                     width="100%"
                    // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2428.2641631797933!2d-1.9946859339039609!3d52.51055827981233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487097f971ba84dd%3A0xe5b0a228a6e9a751!2sSpon%20Ln%2C%20West%20Bromwich%20B70%206AS%2C%20UK!5e0!3m2!1sen!2sin!4v1638194580646!5m2!1sen!2sin"
                    //     width="100%"
                    height="460"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                    />
                </div>
            </Grid>
        </Grid>
    </Container>
    )
}

export default D6_DASAC_CMI
