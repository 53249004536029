import React from 'react';
import './Psy_cat_CSS.css';
import '../../../../MainCSS.css';
import {Paper,Container,Typography,Box,Grid} from "@mui/material";


const Dec6_Services_GPC_Cont_Typ1_Style = {
    fontSize : '1.5rem',
    paddingTop : '70px'
}

function Inpatient_care() {
    return(
    /*    <div className="Dec6_Services_GPC_MainBlock_bg">
        <div className="Dec6_Services_GPC_MainBlock">     */

    <Container className='W_T_cont'>
    <Grid container justify="center" alignItems="center" fixed className="Dec6_Services_GPC_Grid_Psy">
        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_Psy1">
            <Container className="Dec6_Services_GPC_Cont1_Psy">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_Psy_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Psy_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Psy_Paper_Box_H">
                        Stress management 
                        </h3>
                        <i className="fas fa-chevron-circle-right Icon_Right"></i>

                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_Psy2">
            <Container className="Dec6_Services_GPC_Cont1_Psy">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_Psy_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Psy_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Psy_Paper_Box_H">
                        Grief counseling                         </h3>
                        <i className="fas fa-chevron-circle-right Icon_Right"></i>
                   
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_Psy3">
            <Container className="Dec6_Services_GPC_Cont1_Psy">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_Psy_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Psy_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Psy_Paper_Box_H">
                        Anger management                       </h3>
                        <i className="fas fa-chevron-circle-right Icon_Right"></i>
</Box>
                </Paper>
            </Container>
        </Grid>
        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_Psy1">
            <Container className="Dec6_Services_GPC_Cont1_Psy">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_Psy_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Psy_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Psy_Paper_Box_H">
                        Lifestyle disorders                         </h3>
                        <i className="fas fa-chevron-circle-right Icon_Right"></i>

                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_Psy2">
            <Container className="Dec6_Services_GPC_Cont1_Psy">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_Psy_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Psy_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Psy_Paper_Box_H">
                        Assertiveness                      </h3>
                        <i className="fas fa-chevron-circle-right Icon_Right"></i>
 </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_Psy3">
            <Container className="Dec6_Services_GPC_Cont1_Psy">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_Psy_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Psy_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Psy_Paper_Box_H">
                        Self-esteem issues                          </h3>
                        <i className="fas fa-chevron-circle-right Icon_Right Icon_Right"></i>
</Box>
                </Paper>
            </Container>
        </Grid>
        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_Psy1">
            <Container className="Dec6_Services_GPC_Cont1_Psy">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_Psy_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Psy_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Psy_Paper_Box_H">
                        Exam stress                        </h3>  
                        <i className="fas fa-chevron-circle-right Icon_Right Icon_Right"></i>              
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_Psy1">
            <Container className="Dec6_Services_GPC_Cont1_Psy">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_Psy_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Psy_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Psy_Paper_Box_H">
                        Social anxiety                      </h3>       
                                         <i className="fas fa-chevron-circle-right Icon_Right"></i>

                    </Box>
                </Paper>
            </Container>
        </Grid>
        <Grid item lg={4} sm={4} md={6} xs={12} className="Dec6_Services_GPC_Grid_Psy1">
            <Container className="Dec6_Services_GPC_Cont1_Psy">
                <Paper elevation={14} className="Dec6_Services_GPC_Cont1_Psy_Paper">
                    <Box className="Dec6_Services_GPC_Cont1_Psy_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Cont1_Psy_Paper_Box_H">
                        Nicotine cessation program                        </h3>
                        <i className="fas fa-chevron-circle-right Icon_Right"></i>

                      
                    </Box>
                </Paper>
            </Container>
        </Grid>

       
 

    </Grid>
    </Container>
    );
}

export default Inpatient_care;