import React from 'react'
import Online_care_BgImage from './Online_care_BgImage'
import Online_care_below_img from './Online_care_below_img'
import Online_cat from './Online_cat'
import './Online_care.css'
const Online_care = () => {
    return (
        <>
                 <div className='Online_BG'>

          <Online_care_BgImage  />  
          <Online_care_below_img />
          </div>
        <Online_cat />
                </>

    )
}

export default Online_care
