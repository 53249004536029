import React from 'react';
import './Outpatient_care_BgImage_CSS.css';

function Outpatient_care_BgImage() {
    return(
        <div className="Dec6_Industries_BgImage_OutP">

        </div>
    );
}

export default Outpatient_care_BgImage;