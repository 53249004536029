
import React,{useState} from 'react';
import './D6_SL_Header_CSS.css';
import HamBurger from './D6_SL_Hamburger';
import Logo from '../../../assets/Images/logo.png'
/*import Logo1 from '..../src/assets/Images/Logo.svg' */
import {NavLink} from "react-router-dom";
import { Grid } from '@mui/material';

function D6_SL_Header() {

    const [D6_SL_Navbar_Items,setNavbar] = useState(false);
    const changeFixed = () => {
        if(window.scrollY >= 10)
        {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    };
    window.addEventListener('scroll',changeFixed);
    return(
        <>
    {/*    <nav className="D6_SL_Navbar_Items">  */}
        
    <nav className={'D6_SL_Navbar_Items1'}>
            {/* <Grid style={{cursor:'pointer'}} onClick={()=>window.location.href="tel:+91 (0) 9999361401"}textAlign={'center'} container> */}
            <Grid textAlign={'center'} container>   
            <Grid  className='textblank_cont' item sm={4} xs={12}>
                    
                </Grid>
            <Grid  className='text_cont' item sm={4} xs={12}>
                    First Step Wellness
                </Grid>
                <Grid  style={{cursor:'pointer'}} onClick={()=>window.location.href="tel:+91 9999361401"} className='tel_cont' item sm={2} xs={6}>
                    +91 9999361401 &nbsp;
                </Grid>
                <Grid  style={{cursor:'pointer'}} onClick={()=>window.location.href="tel:+91 9967270103"} className='tel2_cont' item sm={2} xs={6}>
                    +91 9967270103
                </Grid>
            </Grid>
        </nav>
        <nav className={D6_SL_Navbar_Items ? 'D6_SL_Navbar_Items active' : 'D6_SL_Navbar_Items'}>
            <div className='logo_n' >
                <NavLink className="D6_SL_Navbar_Link" exact to="/">
                    <img src={Logo} className="D6_SL_Navbar_Items1_Image" alt="SmartLook" /> 
                </NavLink>
            </div>
            <HamBurger/>     
        </nav>
        </>
    );
}

export default D6_SL_Header;
