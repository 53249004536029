import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@mui/material";
import SkinImage from "../../../assets/Images/dual_add.jpg";
import HIcon from '../../../assets/Images/logo.png';
import "../../../MainCSS.css";
import "./Dual_cat.css"
const Dual_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_ContDu">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_GridDu"
        >
          <Grid item sm={12} xs={12} className="D6_ADS_Grid1Du">
            <div className="D6_ADS_G1_DivDu">
             
              <div className='D6_SL_WS_Grid2_HDivDu'>
                <div className='D6_SL_WS_HIconDu'>
                    <img src={HIcon} className='img' />
                </div>
                <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1Du'>DUAL ADDICTION TREATMENT</Typography>
            </div>
  
 </div>
          </Grid>
          <Grid item sm={6} xs={12} className="Grid_Types">
          <Typography component="p"
 
 className="Grid_Types_h">
   First Step Rehab’s dual diagnosis program offers treatment to individuals who suffer from drug and/or alcohol abuse with a co-occurring mental health issues. Because of the destructive relationship between mental illness and addiction, this level of specialized care is essential to long-term recovery.

 </Typography>
 <Typography component="p"
 
 className="Grid_Types_h">
   Dual Diagnosis Treats the Following Mental Health Issues:

 </Typography>
<ul className="ul_dual">
    <li className="li_dual">Mood Disorders </li>
  <li className="li_dual">Bipolar Disorder </li>
  <li className="li_dual">Depression </li>
  <li className="li_dual">Anxiety </li>
  <li className="li_dual">Phobias </li>
  <li className="li_dual">Panic Attacks</li>
  <li className="li_dual">Obsessive-Compulsive Behavior </li>
  <li className="li_dual">Prescription Medication Abuse </li>
  <li className="li_dual">Alcohol Abuse </li>
  <li className="li_dual">Drug Abuse</li>
  <li className="li_dual">Post-Traumatic Stress Disorder</li>
  <li className="li_dual">Depression Due to Chronic Pain </li>
  </ul>

          </Grid>

<Grid item sm={6} xs={12} className="Grid_Types">
<div className="D6_ADS_G1_DivDu" >
              <img src={SkinImage} alt="Image" className="D6_ADS_ImgDu" />
            </div>
         
    
  </Grid>
 <Grid item sm={12} xs={12} className="Grid_Types">
 <Typography component="p"
 
 className="Grid_Types_h">
   Left untreated, these conditions worsen each other and drastically lower your quality of life. That’s where our team of psychiatrists, psychologists, therapists and counselors can help.
    </Typography>
    <Typography component="p"
 
 className="Grid_Types_h">
   Dual Diagnosis Treatment Modalities Include:
    </Typography>
 <ul className="ul_dual">
    <li className="li_dual">Psychiatric Assessment</li>
  <li className="li_dual">Medical Detox</li>
  <li className="li_dual">Medication Evaluation</li>
  <li className="li_dual">Yoga and Meditation</li>
  <li className="li_dual">Group therapy</li>
  <li className="li_dual">Individual counseling</li>
  <li className="li_dual">12 step program</li>
  <li className="li_dual">Cognitive Behavior therapy (CBT)</li>
  <li className="li_dual">Dialectical behavioral therapy (DBT)</li>
  <li className="li_dual">Family support Program</li>
  <li className="li_dual">Follow-ups</li>
  </ul>
    {/* <Typography component="p"
 
 className="Grid_Types_h">
  
&nbsp;    </Typography> */}
  <Typography
          component="p"
          className="D6_ADS_G1_DivPAl_B"
        >
Living with both mental illness and addiction often feels overwhelming. We will work with you at every step of the recovery process: from evaluation and stabilization to discharge and aftercare planning.
 </Typography>
 <Typography
          component="p"
          className="D6_ADS_G1_DivPAl_B"
        >
<span style ={{color: 'green'}} >First Step Rehab is ready and willing to help you. Whatever way you are ready to seek help, we will be ready to listen. So call us or email...</span>
 </Typography>
 

          </Grid>
          {/* <Grid item xs={12} sm={6} className="D6_ADS_Grid1Du">
            <div className="D6_ADS_G1_DivDu" >
              <img src={SkinImage} alt="Image" className="D6_ADS_ImgDu" />
            </div>
          </Grid> */}
        
      
        </Grid>

      </Container>
    </div>
  );
};

export default Dual_cat;
{/* <div>
    
<Container className="D6_ADS_ContDu">
  <Grid
    container
    justify="center"
    alignItems="center"
    fixed
    className="D6_ADS_GridDu"
  >
    <Grid item sm={12} xs={12} className="D6_ADS_Grid1Du">
      <div className="D6_ADS_G1_DivDu">
       
        <div className='D6_SL_WS_Grid2_HDivDu'>
          <div className='D6_SL_WS_HIconDu'>
              <img src={HIcon} className='img' />
          </div>
          <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1Du'>Dual ADDICTION TREATMENT</Typography>
      </div>
      <Typography
          component="p"
          className="D6_ADS_G1_DivPAl_B"
        >
DUAL DIAGNOSIS MEANS THAT AN INDIVIDUAL HAS TWO SEPARATE BUT VERY INTERRELATED DIAGNOSES: 
</Typography>
<Typography
          component="p"
          className="D6_ADS_G1_DivPAl_B"
        >
Dual   is also a highly relapse-prone disease. If not treated, it destroys families and kills the addict. </Typography>
<Typography
          component="p"
          className="D6_ADS_G1_DivPAl_B"
        >
If you or someone you love is addicted to cocaine, get help before it’s too late! </Typography>

<Typography
          component="p"
          className="D6_ADS_G1_DivPAl_B"
        >
OUR FAMILY SUPPORT PROGRAM helps the affected family members cope with the situation during and after the treatment process.  </Typography>


        

</div>
    </Grid>

    <Grid item xs={12} sm={6} className="D6_ADS_Grid1Du">
      <div className="D6_ADS_G1_DivDu" >
        <img src={SkinImage} alt="Image" className="D6_ADS_ImgDu" />
      </div>
    </Grid>
    <Grid item xs={12} sm={12} style={{textAlign:'center',fontSize:'30px',fontWeight:'400',paddingTop:'10px'}}>
      For more information contact us
</Grid>
    <Grid  item xs={12} sm={12} style={{textAlign:'center',paddingTop:'10px'}}>
    <i  className="fas fa-envelope Icon_mail"></i>
    <i  className="fas fa-mobile Icon_mail"></i>

</Grid>

  </Grid>

</Container>
</div> */}