import React from 'react';
import './After_care_BgImage_CSS.css';

function After_care__BgImage() {
    return(
        <div className="Dec6_Industries_BgImage2_After">

        </div>
    );
}

export default After_care__BgImage;