import React from 'react';
import './W_T_below_img.css';

function W_T_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont">
            <h3 className="Dec6_Services_WCU_Cont_H">
                What Do We Treat
            </h3>
            <p className="Dec6_Services_WCU_Cont_P">
            We recommends the 12-step program, followed at First Step Wellness for both during and after the treatment program. The 12-step program is a lifelong path to recovery and rehabilitation from addiction.             </p>
            <i className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon"></i>
        </div>
    );
}

export default W_T_below_img;