import React from 'react'
import Services_BgImage from './Services_BgImage'
import Services_cat from './Services_cat'
const Services = () => {
    return (
        <>
          <Services_BgImage  />  
          <Services_cat />
        </>
    )
}

export default Services
