import React from 'react';
import './Psy_care_BgImage_CSS.css';

function Psy_care_BgImage() {
    return(
        <div className="Dec6_Industries_BgImage_Psy">

        </div>
    );
}

export default Psy_care_BgImage;